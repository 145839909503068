import React, {  useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, LinearProgress, TableBody, Typography,Table,TableContainer,
        TableHead,TableCell,TableRow, Tooltip, IconButton} from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import RefreshIcon from '@material-ui/icons/Refresh';
import {AuthContext} from '../context/AuthContext'
import ModalTags from './ModalTags'
import { authUser } from '../funciones/AuthUser'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';

const useStyles = makeStyles((theme) => ({
    root: {
      //alignContent: 'center',
      marginTop : '.5em',
      paddingLeft:0.5+'rem'
    },
    container: {
        width:300,
        minHeight: 280,
        //minWidth:'100%',
      },
      container2: {
        maxHeight: 550,
        //maxHeight: window.innerHeight-170,
        //minHeight: 280,
      },
      tableRow: {
        "&$selected, &$selected:hover": {
          backgroundColor: "#DF0006"
        }
      },
      tableCell: {
        "$selected &": {
          color: "white"
        }
      },
      hover: {},
      selected: {}
}));

export default function TablaRevistaTag({seleccionado}){
    const alturaTabla = window.innerHeight<937 ? window.innerHeight-500 : window.innerHeight-650
    const classes = useStyles();
    const [espera,setEspera] = useState(false)
    const [listaTag,setListaTag] = useState([])
    const [idSelec,setIdSelec] = useState(-1);
    const [modalTags,setModalTags] = useState(false);
    const [listCategoria,setListCategoria] = useState([])
    const [listFiltrada,setListFiltrada] = useState([])
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const [actualizar,setActualizar] = useState(false);
    let historial = useHistory();   

    useEffect(()=>{
        const source = axios.CancelToken.source();    
        async function getTags(){
            setEspera(true)
            let auxU=process.env.REACT_APP_LINK +`/revista-tag-list`;  
            let usuid = localStorage.getItem('UsuId') 
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({     
                'revista':seleccionado.Id,                          
                'usuario':usuid,
                'idDispositivo':'wed4513s1c2s1cds1cd'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: {
                        "access-token": token,
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux[0].Id>-1){
                        setListaTag(aux)
                    }else{
                        setListaTag([])
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
                setEspera(false)
        }
    async function getCategorias(){
       // setEsperaLlamada(true)
        let auxU=process.env.REACT_APP_LINK +`/revista-categoria-list`;  
        let usuid = localStorage.getItem('UsuId') 
        let token = localStorage.getItem("token20");
        let dataInfo = qs.stringify({                               
            'usuario':usuid,
            'idDispositivo':'wed4513s1c2s1cds1cd'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: {
                "access-token": token,
                "Content-Type": "application/x-www-form-urlencoded",
                },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {          
            aux=  response.data;    
            if(aux[0].Id>-1){
                setListCategoria(aux)
                //console.log(aux)
            }
        }).catch(function (error) {          
            console.log(`Error: ${error}`);        
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }
        })
        //setEsperaLlamada(false)
        //setListaActivos(listTags)
    }
        const autenticado = authUser();
      if(!autenticado){
        localStorage.clear();
        historial.push('/');
        guardarUsuario([]);    
      }else{
        const usu= localStorage.getItem('UsuarioNom');
        const nvl= localStorage.getItem('Tipo');           
        if (usuario.length===0) 
        {
            guardarUsuario({Nombre:usu,nivel:nvl});                                         
            
        }   
        getTags();
        getCategorias()
    }

    },[seleccionado,actualizar])


    const filaSelec=(auxTag)=>{
        setIdSelec(auxTag.Id)
      }

    function abrirModalTags(){  
        let arregloFiltrado=[]
        listaTag.forEach((catRegistro)=>{
            let arreglo=listCategoria.filter((catego)=>catego.Des===catRegistro.Des)
            arregloFiltrado.push(arreglo[0])
        })        
        setListFiltrada(arregloFiltrado)
        setModalTags(!modalTags);    
    }

      function reiniciar(){
          setActualizar(!actualizar)
      }

    function sinDatos(){
        return(
            <TableContainer className={classes.container2} style={{height: 150}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow >                                        
                           {/*
                           <TableCell style={{ color:'#DF0006',fontWeight:600}} align="center" padding="none">
                                0  
                            </TableCell>  
                           */} 
                            {/* <TableCell style={{ color:'#F96550',fontWeight:600}} align="left" padding="none">
                                Descripción 
                            </TableCell>   */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {/*
                                <TableCell padding='none' align="center"></TableCell>    
                            */}           
                            <TableCell padding='none' align="center">Sin Resultados</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function generarTabla(){
           return( <TableContainer className={classes.container} style={{height: alturaTabla}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow >   
                            {/*
                                <TableCell style={{ color:'#DF0006',fontWeight:600}} align="center" padding="none">
                                            {listaTag.length}    
                                </TableCell> 
                            */}                                     
                        {/*     <TableCell style={{ color:'#F96550',fontWeight:600}} align="left" padding="none">
                                Descripción 
                            </TableCell>  */}  
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listaTag.map((tag,index)=>{
                            return(
                                <TableRow onClick={ () => { filaSelec(tag); } }
                                className={classes.tableRow} selected={ idSelec === tag.Id}
                                classes={{ hover: classes.hover, selected: classes.selected }}  >
                                {/*
                                <TableCell className={classes.tableCell} padding='none' align="center" style={{width:'20px'}}>
                                    <b>{tag.Id}</b> 
                                </TableCell>    
                                */}           
                                <TableCell className={classes.tableCell} padding='none' align="left" style={{width:'300px',paddingLeft:'.4em'}}>
                                    {tag.Des}
                                </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            )
    }

    const modTag = modalTags ? <ModalTags modalAbierto={modalTags} setModalAbierto={setModalTags} listTags={listFiltrada} titulo={"Editar Tags "+seleccionado.Nom}
                                    setActualizar={setActualizar} actualizar={actualizar} seleccionado={seleccionado}
                                    listCategoria={listCategoria} //listCategoria={listCategoria}
                                /> : null;

    const contenido = listaTag.length!==0 ? generarTabla() : sinDatos();
    const validaCont = espera ? <Box sx={{width:'100%'}}><LinearProgress style={{color:'#F96550'}}/></Box> : contenido;
    return( 
    <div className={classes.root}>
        <Box display="flex" row flexWrap="wrap" style={{marginBottom:'1em'}}>
            <Box display="flex" pr={1} >
                <Typography component="h5" color="secondary">
                    Tags de
                </Typography>
            </Box>
            <Box display="flex">
                <Typography component="h5" >
                    <b>{seleccionado.Nom}</b>
                </Typography>
            </Box>
            <Box style={{height:'fit-content'}}>
                <Tooltip title={`Actualizar tabla`}>
                    <IconButton   size="small"  onClick={reiniciar}
                        component="span"   aria-label="add circle"
                    >
                        <RefreshIcon color="secondary"  />  
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Editar Tags`}>
                    <IconButton   size="small"  onClick={abrirModalTags}
                        component="span"   aria-label="add circle"
                    >
                        <CreateIcon color="secondary"  />  
                    </IconButton>
                </Tooltip>
            </Box>
            {validaCont}
            {modTag}
        </Box>
    </div>
    )
}