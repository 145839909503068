import React , {useState} from 'react'
import Modal from '../generales/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,Grid,TextField} from '@material-ui/core'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: {
      margin: theme.spacing(1),
    },
  },

  formAnotacion: {
    width:'15em'
  },

}));


  export default function NuevaCategoria({modalAbierto,setModalAbierto,setActualizar,actualizar}){
    let historial = useHistory();   
    const classes = useStyles();
    const [espera,setEspera]=useState(false)
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);
    const [reiniciar,setReiniciar] = useState(false)
    const [nombre,setNombre] = useState("");

    const expPermitidaNombre = /^([A-Za-záéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z _.,ñÑáéíóúÁÉÍÓÚ]*)$/

    const onChangeNombre = (e) =>{
      if(expPermitidaNombre.test(e.target.value)||e.target.value===' '||e.target.value===''){
        setNombre(e.target.value);
      }
    }
  
    function verificarNombre(nom){
      let validado = false;
      if(nom && nom!=" "){
        if(expPermitidaNombre.test(nom)){
          validado=true
        }else{
          validado = false;
          setMensaje("La descripción sólo puede incluir letras y espacios");
          setAviso(true);
          setTipoAdvertencia("error");
        }
      }else{
        validado = false;
        setMensaje("Ingrese descripción");
        setAviso(true);
        setTipoAdvertencia("error");
      }
      return validado;
    }

      async function registrarCategoria(){
        setEspera(true)
        setBtnDeshabilitado(true)
        const source = axios.CancelToken.source();    
        let auxU=process.env.REACT_APP_LINK +`/revista-categoria-nuevo`;  
        let token = localStorage.getItem("token20");
        let usuid = localStorage.getItem('UsuId') 
        let dataInfo = qs.stringify({                               
          'usuario':usuid,
          'status':1,
          'descr':nombre,
          'idDispositivo':'wed4513s1c2s1cds1cd'
          });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: {
                    "access-token": token,
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                data: dataInfo,
                cancelToken: source.token,
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux.respuesta===1){
                  let mensaje = "Se agregó correctamente la categoría";
                  setNombre("")
                  setTipoAdvertencia("success");
                  setMensaje(mensaje);
                  setAviso(true);   
                  setEspera(false)
                  setReiniciar(true)
                }else{
                  let mensaje = "Ocurrió un error, intente más tarde";
                  setTipoAdvertencia("warning");
                  setMensaje(mensaje);
                  setAviso(true);   
                  setEspera(false)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        historial.push("/");                              
                    } 
                }
            })
            setBtnDeshabilitado(false)
        }

    
    const handleSubmission = ()=>{
      if(verificarNombre(nombre)){
        registrarCategoria()
      } 
    };


    return(
      <Modal
        titulo={"Nueva Publicación"}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        guardarDatos={handleSubmission}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso}
        tamanio={'l'}
        cargando={espera}    
        setActualizar={setActualizar}
        actualizar={actualizar}
        reiniciar={reiniciar}    
    >
        <form className={classes.root} noValidate autoComplete="off"> 
            <Grid container spacing={1}>
                <Grid item>
                  <FormControl className={classes.formAnotacion}>
                    <TextField required label="Descripción"
                                  value={nombre} onChange={onChangeNombre}id={'nombre'}/> 
                  </FormControl>
                </Grid>
            </Grid>
        </form>
    </Modal>
    )
  }