import React, {useState,useEffect} from 'react'
import ModalInformativo from '../generales/ModalInformativo';
import {Box , Typography, LinearProgress, Card,} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NumberFormat from 'react-number-format';
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import { authUser } from '../funciones/AuthUser'
import axios from 'axios'
import qs from 'qs'

const useStyles = makeStyles((theme) => ({
    tamanioLetraTitulos:{
      fontSize:13,
      marginTop:'.11em'
    },
    tamanioLetraPubli:{
        fontSize:13,
        marginRight:'.3em',
        marginTop:'.1em'
      },
    tamanioLetra:{
      fontSize:15
    },
    estiloBoxes:{
        paddingTop:.5+'rem',
        paddingBottom:.5+'rem',
        paddingLeft:.5+'rem',
        justifyContent:'center'
    },
    estiloCards:{
      marginBottom:'1em',
    },
    estiloCardImagen:{
        display:'flex',
        justifyContent:'center'
    },
    root:{
      alignItems:'center',
      width:'100%'
    },
    titulos:{
        fontWeight:600,
        marginBottom:'.5em',
        fontSize:19
    }
  }));

export default function InfoPubli({seleccionado, setModalAbierto,modalAbierto,rev,titulo}){
    const classes = useStyles();
    const [espera,setEspera] = useState(false)
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const [info,setInfo] = useState([])
    const [listaCat, setListaCat] = useState([])

    let historial = useHistory(); 
    useEffect(()=>{
        const source = axios.CancelToken.source();    
        async function getInfo(){
            setEspera(true)
            let auxU=process.env.REACT_APP_LINK +`/publicacion-id`;  
            let usuid = localStorage.getItem('UsuId') 
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({  
                'publicacion': seleccionado.Id,                             
                'usuario':usuid,
                'idDispositivo':'wed4513s1c2s1cds1cd'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: {
                        "access-token": token,
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data; 
                    if(aux[0].Id>-1){
                        setInfo(aux[0])
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
                setEspera(false)
        }
 
        async function getTags(){
            setEspera(true)
            let auxU=process.env.REACT_APP_LINK +`/revista-tag-list`;  
            let usuid = localStorage.getItem('UsuId') 
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({     
                'revista':rev.Id,                          
                'usuario':usuid,
                'idDispositivo':'wed4513s1c2s1cds1cd'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: {
                        "access-token": token,
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux[0].Id>-1){
                        setListaCat(aux)
                    }else{
                        setListaCat([])
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
            setEspera(false)
        }

        const autenticado = authUser();
        if(!autenticado){
            localStorage.clear();
            historial.push('/');
            guardarUsuario([]);    
        }
        else{
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');           
            if (usuario.length==0) 
            {
                guardarUsuario({Nombre:usu,nivel:nvl});                                         
                
            }
            getInfo()
            getTags()
        }
    },[seleccionado])

    function addCero (valor)
    {   let auxValor=0
        let valorStr=valor.toString()
        let ini=valorStr.indexOf(".")
        if (ini!== -1) 
        {   let cortado=valorStr.substr(ini,valor.length)
            if (cortado.length == 2  ) 
            {   auxValor=(valorStr+"0")
            }
            else
            {   auxValor=valor+0.00  
            }
        }
        else
        {   auxValor=valor+0.00      
        }
        return auxValor;
    }  


    function generarImagen(){
        var img1 =<div></div>
        var img2 =<div></div>
        if(seleccionado.Portada)
        {   img1=<img src={seleccionado.Portada}  style={{height:"25 rem",width:"15rem", borderRadius: '.6rem'}} />
        }
        else
        {   img1=<img src="T_LOGO_1.svg" style={{height:"25 rem",width:"15rem"}} />
        }
        if(seleccionado.Secundaria)
        {   img2=<img src={seleccionado.Secundaria} style={{height:"25 rem",width:"15rem", borderRadius: '.6rem'}} />
        }
        else
        {   img2=<img src="T_LOGO_1.svg" style={{height:"25 rem",width:"15rem"}} />
        }
        return(
        <Card className={classes.estiloCardImagen} >
            <Box style={{justifyContent:'center',marginRight:'3em' ,marginBottom:'1em',marginTop:'1em'}}
                display="flex" flexDirection="column" 
            >
                <Typography align='center' className={classes.titulos}>
                    Portada
                </Typography>
                {img1}
            </Box>
            <Box style={{justifyContent:'center',marginBottom:'1em',marginTop:'1em'}}
                display="flex" flexDirection="column" 
            >
                <Typography align='center'  className={classes.titulos}>
                    Contraportada
                </Typography>
                {img2}
            </Box>
        </Card>
        )
    }

    const validarRegistro = ( info.FAlta || info.FPublica || info.Issn || info.Num || 
                            info.Pagina || info.Precio || info.Token || listaCat.length !== 0 ) ? true:false
    function generarInfo(){
        return( 
        <div>
        {validarRegistro ?
        <Card className={classes.estiloCards}>
            <Box display="flex"  flexDirection="row">                
                <Box width="10%"  >                    
                    {info.Precio && info.Precio!=="0"?
                        <Box display="flex" pl={1} style={{paddingTop:'.8rem'}} >
                        <b >
                        <NumberFormat thousandSeparator={true}  className={classes.tamanioLetra}
                            displayType="text" prefix={'$'} value={addCero(info.Precio)}                            
                            style={{fontSize:1.4+'rem'}}
                        /></b>
                        </Box>
                    : null}               
                </Box>
                <Box  display="flex"   width="90%"  >                                    
                <Box display="flex" row width="100%"  flexWrap="wrap" className={classes.estiloBoxes}>
                    {info.Pagina ?
                    <Box display="flex" pr={1}>
                        <Typography className={classes.tamanioLetraTitulos} style={{color:'#F96550'}}>
                            {'Pág.'}
                        </Typography>
                    </Box>
                    :null}
                    {info.Pagina ?
                    <Box display="flex" pr={1}>
                        <Typography className={classes.tamanioLetra}>
                            {info.Pagina}
                        </Typography>
                    </Box>
                    :null} 
                    {info.FAlta ? (moment.utc(info.FAlta).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? "" :   
                        <Typography className={classes.tamanioLetraPubli} style={{color:'#F96550'}}>
                        {'Alta'}
                        </Typography>)
                    :""}
                    {info.FAlta ? (moment.utc(info.FAlta).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? "" :
                        <Box display="flex" pr={1}>
                            <Typography className={classes.tamanioLetra}  style={{fontWeight:600}}>
                                { moment.utc(info.FAlta).format("DD/MM/YYYY")}
                            </Typography>
                        </Box>)
                    :""}
                    {info.FPublica ? (moment.utc(info.FPublica).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? "" :   
                        <Typography className={classes.tamanioLetraPubli} style={{color:'#F96550'}}>
                        {'Publicación'}
                        </Typography>)
                    :""}
                    {info.FPublica ? (moment.utc(info.FPublica).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? "" :
                    <Box display="flex" pr={1}>
                        <Typography className={classes.tamanioLetra}  style={{fontWeight:600}}>
                            { moment.utc(info.FPublica).format("DD/MM/YYYY")}
                        </Typography>
                    </Box>)
                    :""}
                    {info.Token ?
                        <Box display="flex" pr={1} >
                            <Typography className={classes.tamanioLetraTitulos} style={{color:'#F96550'}}>
                                {'Token'}
                            </Typography>
                        </Box>
                    :null}
                    {info.Token ?
                    <Box display="flex" pr={1}>
                        <Typography className={classes.tamanioLetra}>
                            {info.Token}
                        </Typography>
                    </Box>
                    :null}
                    {info.Num ?
                    <Box display="flex" pr={1}>
                        <Typography className={classes.tamanioLetraTitulos} style={{color:'#F96550'}}>
                            {'Número'}
                        </Typography>
                    </Box>
                    :null}
                {info.Num ? 
                <Box display="flex" pr={1}>
                    <Typography className={classes.tamanioLetra}>
                        {info.Num}
                    </Typography>
                </Box>
                :null}
                                       
                {listaCat.lengh!==0 ?
                    <Box display="flex" pr={1}>
                        <Typography className={classes.tamanioLetraTitulos} style={{color:'#F96550'}}>
                            {'Tags'}
                        </Typography>
                    </Box>
                :null}
                {listaCat.lengh!==0 ?
                    listaCat.map((tag,index)=>{
                        var elem=<div></div>
                        if(index!==listaCat.length-1)
                        {   elem =  <Box display="flex" pr={1} key={index}>
                                        <Typography className={classes.tamanioLetra}>
                                            {tag.Des+", "}
                                        </Typography>
                                    </Box>
                        }
                        else
                        {   elem =  <Box display="flex" pr={1} key={index}>
                                        <Typography className={classes.tamanioLetra}>
                                            {tag.Des}
                                        </Typography>
                                    </Box>
                        }
                        return( elem )
                    })
                :null} 
                </Box>
                </Box>
                </Box>
            </Card>
        :null}
        {generarImagen()}
        </div>
        )
    }
    const contenido = info.length!==0 ? generarInfo() : <Typography>Sin Datos Disponibles</Typography>
    const validaCont = espera ? <Box sx={{width:'100%'}}><LinearProgress/></Box> : contenido;

    return(
    <ModalInformativo setModalAbierto={setModalAbierto}
        modalAbierto={modalAbierto} titulo={titulo}
        tamanio='sm' colorFondo='#C4C4C4'
    >
        {validaCont}
    </ModalInformativo>
    )
}