import React , {useState, useEffect} from 'react'
import Modal from '../generales/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,Grid,TextField} from '@material-ui/core'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: {
      margin: theme.spacing(1),
    },
  },
  formEncabezado:{
    marginTop:'1.7em',  
  },
  formAnotacion: {
    width:'15em'
  },
  formDesc:{
      marginTop:'1.7em',
      width:'30em',
      height:'6em',
      color:'white',
      backgroundColor:'#424242'
  },
  formImg:{
    marginTop:'1.7em',
    width: '100px',
    height:'100px',
    border: '1px solid black'
  }
}));

  export default function NuevaRevista({modalAbierto,setModalAbierto,actualizar,setActualizar}){
    const classes = useStyles();
    let historial = useHistory();   
    const [espera,setEspera]=useState(false)
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [reiniciar,setReiniciar] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [nombre,setNombre] = useState("");
    const [desc,setDesc] = useState("");
    const expPermitidaDecimal = /^(\d+\.?\d*|\.\d+)$/;
    const expPermitidaEnteros = new RegExp("^[0-9]+$");
    const exPermitidaNombre = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]')

    const onChangeNombre = (e) =>{
      if(exPermitidaNombre.test(e.target.value)||e.target.value===' '||e.target.value===''){
        setNombre(e.target.value);
      }
    }
  
    const onChangeDesc = (e) =>{
        if(exPermitidaNombre.test(e.target.value)||e.target.value===' '||e.target.value===''){
          setDesc(e.target.value);
        }
      }

 

      function verificarNombre(nom){
        var validado=true
        var auxNom=""
        if(expPermitidaEnteros.test(nom)||expPermitidaDecimal.test(nom)){
          auxNom=parseInt(nom)
        }else{
          auxNom=nom.trim()
        }
        if(auxNom && auxNom!=" "){
          if(exPermitidaNombre.test(auxNom.toString())){
            validado=true
          }else{
            validado = false;
            setMensaje("El nombre sólo puede incluir letras, dígitos y espacios");
            setAviso(true);
            setTipoAdvertencia("error");
          }
        }else{
          validado = false;
          if(auxNom===0){

            setMensaje("Ingrese nombre válido");
          }
          else{
            
          setMensaje("Ingrese nombre");
          }
          setAviso(true);
          setTipoAdvertencia("error");
        }
        return validado;
      }


  
      function verificarDesc(desc){
        let validado = false;
        var auxDesc=""
        if(expPermitidaEnteros.test(desc)||expPermitidaDecimal.test(desc)){
          auxDesc=parseInt(desc)
        }else{
          auxDesc=desc.trim()
        }
        if(auxDesc && auxDesc!=" "){
          if(exPermitidaNombre.test(auxDesc.toString())){
            validado=true
          }else{
            validado = false;
            setMensaje("La descripción sólo puede incluir letras, dígitos y espacios");
            setAviso(true);
            setTipoAdvertencia("error");
          }
        }else{
          validado = false;
          if(auxDesc===0){
            setMensaje("Ingrese descripción válida");
          }else{
            setMensaje("Ingrese descripción");
          }
          setAviso(true);
          setTipoAdvertencia("error");
        }
        return validado;
      }

    async function registrarRevista(auxFecha){
      setEspera(true)
      setBtnDeshabilitado(true)
      const source = axios.CancelToken.source();    
      let auxU=process.env.REACT_APP_LINK +`/revista-nuevo`;  
      let token = localStorage.getItem("token20");
      let usuid = localStorage.getItem('UsuId') 
      let dataInfo = qs.stringify({                               
        'usuario':usuid,
        'fecha':auxFecha,
        'status':1,
        'nombre':nombre.trim(),
        'descr':desc.trim(),
        'idDispositivo':'wed4513s1c2s1cds1cd'
        });
          let config = {
              url:auxU ,
              method: 'POST',
              headers: {
                  "access-token": token,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              data: dataInfo,
              cancelToken: source.token,
          };
          let aux ={};           
          await axios(config)
          .then(function (response) {          
              aux=  response.data;    
              if(aux.respuesta===1){
                let mensaje = "Se agregó correctamente la revista";
                setNombre("")
                setDesc("")
                setTipoAdvertencia("success");
                setMensaje(mensaje);
                setAviso(true);  
                setReiniciar(true)
              }else{
                let mensaje = "Ocurrió un error, intente más tarde";
                setTipoAdvertencia("warning");
                setMensaje(mensaje);
                setAviso(true);   
              }
          }).catch(function (error) {          
              console.log(`Error: ${error}`);        
              if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
              {
                  console.log('datos incorrectos' );  
              }else
              {
                  if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                      ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                  {
                      console.log('loguearse de nuevo');
                      localStorage.clear();                      
                      historial.push("/");                              
                  } 
              }
          })
          setEspera(false)
          setBtnDeshabilitado(false)
      }
    const handleSubmission = ()=>{
      if(verificarNombre(nombre)){
        if(verificarDesc(desc)){
          let nuevaFecha = new Date()
          let fec = moment(nuevaFecha).format();   
          let fecAux = fec.substring(0,fec.lastIndexOf('-'))                                                                              
          registrarRevista(fecAux)    
        }
      } 
    };


    return(
      <Modal
        titulo={"Nueva Revista"}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        guardarDatos={handleSubmission}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso}
        tamanio={'md'}
        cargando={espera}    
        actualizar={actualizar}
        setActualizar={setActualizar}
        reiniciar={reiniciar}       
    >
        <form className={classes.root} noValidate autoComplete="off"> 
            <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl className={classes.formAnotacion}>
                    <TextField required label="Nombre"
                                  value={nombre} onChange={onChangeNombre}id={'nombre'}/> 
                  </FormControl>
                </Grid>
            </Grid>
            <textarea className={classes.formDesc} placeholder="Descripción"
              value={desc} onChange={onChangeDesc} id={'desc'}
              multiline rows={2} maxRows={4}/>
        </form>
    </Modal>
    )
  }