import React,{useState,useEffect} from 'react'
import {Box,Tooltip,IconButton,Table,TableBody,TableCell,LinearProgress,
    TableContainer,TableHead,TableRow} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuPublicacion from './MenuPublicacion'
import EditarPublicacion from "./EditarPublicacion2";
//import VisualizadorPubli from "./VisualizadorPubli.js";
//import PageviewIcon from '@material-ui/icons/Pageview'
import NumberFormat from 'react-number-format'
import moment from 'moment';
import qs from 'qs'
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      minWidth:500,
      maxWidth:'100%',
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      //minWidth:'100%',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#DF0006"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          

    {
      id:2,
      titulo:"Publicación",                                                
      alinear:'center'
    }, 
    {
      id:4,
      titulo:"Nombre",                                                
      alinear:'center'
    },   
    {
      id:3,
      titulo:"Token",                                                
      alinear:'center'
    },  
     
    {
        id:5,
        titulo:"Precio",                                                
        alinear:'center'
      },       
     
  ];
export default function TablaPublicacion({setSeleccionado,lista,rev,setActualizar,actualizar}) {
  const alturaTabla = window.innerHeight<937 ? window.innerHeight-200 : window.innerHeight-270
  const valorInicial = {
    mouseX: null,
    mouseY: null,
  };
  const classes = useStyles();
  const [datos, guardarDatos] = useState([])  
  const [espera,setEspera]= useState(false);
  const [selectedID, setSelectedID] = useState(null); 
  const [valorCursor, setValorCursor] = React.useState(valorInicial);
  const [menuSeleccionado,setMenuSeleccionado]= useState([]); 
  const [modalEditar,setModalEditar]= useState(false); 
  const [tituloEditar,setTituloEditar]= useState(""); 
  useEffect(()=>
  {
    setEspera(true)
    guardarDatos(lista)
    setEspera(false)            

  },[])

  const selecFila=(auxPubli)=>{
    setSelectedID(auxPubli.Id)
    setSeleccionado(auxPubli)
  }
  
  const cerrarMenu = (e) => {
    e.preventDefault()
    if ((e.KeyCode !== 9))
    { setValorCursor(valorInicial);   
      //console.log(e.KeyCode);
    }
      
  };

  function guardarPosCursor(event,registro) {
    event.preventDefault();
    setValorCursor({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    //console.log(registro);
    setMenuSeleccionado(registro)
    setSelectedID(registro.Id)
  };
    

  function Renglon(props) {
    const { row } = props;      
    return (
    <React.Fragment>
      <TableRow onClick={ () => { selecFila(row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }} 
        onContextMenu={(event)=>guardarPosCursor(event,row)}        
      >
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:80+'px',fontSize:15+'px'}} >
            <b>{row.FPublica ? moment.utc( row.FPublica).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? "" : moment.utc( row.FPublica).format('DD/MM/YYYY') : ""}</b>
        </TableCell>   
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:200+'px',fontSize:12+'px',paddingLeft:.5+'rem'}} >
          {row.Nombre}
        </TableCell>   
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:100+'px',fontSize:12+'px',paddingLeft:.5+'rem'}} >
          {row.Token}
        </TableCell>  
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:80+'px',fontSize:12+'px',paddingLeft:.5+'rem'}} >
         <NumberFormat value={row.Precio} thousandSeparator={true} fixedDecimalScale={2} prefix="$" displayType='text'/>
        </TableCell>                                                                               
      </TableRow>        
    </React.Fragment>
    );
  }
  function tablaCompleta(auxlista){
  //console.log("entrando sin preguntar");
  return(
    <TableContainer className={classes.container} style={{height:alturaTabla}}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>                                           
            {columnas.map(row => {              
              return(  
                <TableCell style={{ color:'#F96550',fontWeight:600}} 
                  key={row.id}    align={row.alinear} padding={'none'} 
                >
                  {row.titulo}
                </TableCell>    
              )                
            })}           
          </TableRow>
        </TableHead>
        <TableBody>
            {auxlista.length!==0 ? (auxlista.map((row) => (
                <Renglon key={row.Id} row={row} />
            ))):null}
        </TableBody>
      </Table>
    </TableContainer>
  )}
    const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>     
            {columnas.map(row => {              
              return(  
              <TableCell style={{ color:'#F96550',fontWeight:500}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
              )                
            })}                                                
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow}> 
          <TableCell  className={classes.tableCell} align="center"> </TableCell>         
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>          
          <TableCell  className={classes.tableCell} align="left">Publicaciones</TableCell>             
          <TableCell  className={classes.tableCell} align="center"></TableCell>
          <TableCell  className={classes.tableCell} align="left">Registradas</TableCell>             
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    )}

    function validarNulos(cadena){
      let respuesta=false
      if (cadena !== null && cadena !== undefined && cadena !== "" 
        && cadena !== ''&& cadena !== ' ') 
      {
        respuesta =true  
      }
      return respuesta
    }

    function abrirModalEditar(){
      //let auxTitulo=validarNulos(seleccionado.Nombre)?"Editar "+seleccionado.Nombre:"Editar "+revista.Nom
      const auxRev = qs.parse(localStorage.getItem('revista')) 
      let auxTitulo= validarNulos(menuSeleccionado.Nombre) ? menuSeleccionado.Nombre : auxRev.Nom
      setTituloEditar(auxTitulo)
      setModalEditar(true)
    }

  const menuPubli = valorCursor.mouseY !== null ? <MenuPublicacion valorCursor={valorCursor} handleClose={cerrarMenu}
                                                    seleccionado={menuSeleccionado} rev={rev} actualizar={actualizar} setActualizar={setActualizar} 
                                                    abrirModalEditar={abrirModalEditar}
                                                    />
                                                   :null
  const contenido = datos.length!==0 ?tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
  const modEditar = modalEditar ? (
    <EditarPublicacion
      modalAbierto={modalEditar}
      setModalAbierto={setModalEditar}
      seleccionado={menuSeleccionado}
      rev={rev}
      titulo={tituloEditar}
      setActualizar={setActualizar}
      actualizar={actualizar}
    />
  ) : null;

  return (
  <div>      
    {tabla}   
    {menuPubli}  
    {modEditar}
  </div>
  )
}