import React,{useState,useEffect} from 'react';
import Modal from '../generales/Modal';
import {Radio, Table,TableBody,TableCell,TableContainer,
        TableHead,TableRow, Box, LinearProgress} from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import { authUser } from '../funciones/AuthUser'
import axios from 'axios';
import qs from 'qs';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      width: '450px',
      backgroundColor:'white',
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },

    hover: {},
    selected: {}
  });

export default function ModalTags({listTags,listCategoria,seleccionado,setModalAbierto,modalAbierto,setActualizar,actualizar,titulo}){
    let historial = useHistory();
    const classes = useStyles();
    const [espera, setEspera] = useState(false)
    const [esperallamada,setEsperaLlamada] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [reiniciar,setReiniciar] = useState(false)
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    //const [listCategoria,setListCategoria] = useState([])
    const [listaActivos,setListaActivos] = useState([])
    const [listaInactivos,setListaInactivos] = useState([])
    const [idSelec,setIdSelec]=  useState(-1);    
    const [listaTagsLocal,setListaTagsLocal] = useState()
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    
    useEffect(()=>{
        const source = axios.CancelToken.source(); 
        console.log(listTags);   
        setListaActivos(listTags)
        setListaTagsLocal(listTags)
        async function getCategorias(){
            setEsperaLlamada(true)
            let auxU=process.env.REACT_APP_LINK +`/revista-categoria-list`;  
            let usuid = localStorage.getItem('UsuId') 
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({                               
                'usuario':usuid,
                'idDispositivo':'wed4513s1c2s1cds1cd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: {
                    "access-token": token,
                    "Content-Type": "application/x-www-form-urlencoded",
                    },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1){
                    //setListCategoria(aux)
                    console.log(aux)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
            })
            setEsperaLlamada(false)
            setListaActivos(listTags)
        }
        
        const autenticado = authUser();
        if(!autenticado){
            localStorage.clear();
            historial.push('/');
            guardarUsuario([]);    
        }else{
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');           
            if (usuario.length===0) 
            {
                guardarUsuario({Nombre:usu,nivel:nvl});                                         
                
            }                           
            //getCategorias();
        }
    },[seleccionado])


    function selecFila(cat,pos){                
        setIdSelec(cat.Id);
        if(listaActivos.find(  (registro) => registro.Id === cat.Id))
        {
            setListaActivos(listaActivos.filter(item => item.Id !== cat.Id) )  
            if(!listaInactivos.find((registro) => registro.Id === cat.Id)&&listTags.find((registro) => registro.Id === cat.Id))
            {
                setListaInactivos(prevItems => 
                    [...prevItems, { Id:cat.Id}]
                )
            }
        }
        else
        {
            setListaActivos(prevItems => 
                [...prevItems, { Id:cat.Id }]
            )   
            if( listaInactivos.find((registro) => registro.Id === cat.Id ) )
            {
                setListaInactivos( listaInactivos.filter(item => item.Id !== cat.Id) )
            }
        }
    }


    const handleSubmission = ()=>{
        let arreObj=[]
        var auxFiltro=[]
        var auxIna=listaInactivos
        var duplicados=0
        //console.log(listaActivos);
    /*     if(listaTagsLocal.length!==0&&listaActivos.length!==0){
            for(let i=0;i<listaActivos.length;i++){
                let tag= listaActivos[i]
                let index = listaTagsLocal.findIndex(elem => elem.Id === tag.Id);
                console.log(index)
                if(index<0){
                    auxFiltro.push(tag)
                }
            }
        } */
        if(listaActivos.length!==0){
            for(let i=0;i<listaActivos.length;i++){
                let tag= listaActivos[i]
                if(listaTagsLocal.length!==0){
                    let index = listaTagsLocal.findIndex(elem => elem.Id === tag.Id);
                    console.log(index)
                    if(index<0){
                        auxFiltro.push(tag)
                    }
                }else{
                    auxFiltro.push(tag)
                }
            }
        }
        //console.log(auxFiltro);
        let auxArrTot = auxFiltro.concat(auxIna)    
        //console.log(auxArrTot);    
        if (auxArrTot.length!==0) {
            if(auxFiltro.length!==0){
                auxFiltro.forEach((catego)=>{
                    arreObj.push({idCateg:catego.Id,status:1,accion:"INSERT"})
                })
            }   
            if(auxIna.length!==0){
                auxIna.forEach((catego)=>{
                    arreObj.push({idCateg:catego.Id,status:1,accion:"DELETE"})
                })
            } 
            console.log(arreObj); 
            actualizarTags(arreObj);
        }
        else{
            setMensaje("Agregue o elimine tags");
            setAviso(true);
            setTipoAdvertencia("error");
        }	
    };

      async function actualizarTags(auxArreglo){
        setEspera(true)
        setBtnDeshabilitado(true)
        const source = axios.CancelToken.source();    
        let auxU=process.env.REACT_APP_LINK +`/revista-tag-editar`;  
        let token = localStorage.getItem("token20");
        let usuid = localStorage.getItem('UsuId') 
       /* var bodyFormData = new FormData();
        bodyFormData.append('usuario',usuid)
        bodyFormData.append('revista',seleccionado.Id)
        bodyFormData.append('operacion',JSON.stringify(auxArreglo))
        bodyFormData.append('idDispositivo','wed4513s1c2s1cds1cd')*/
        
      let dataInfo = qs.stringify({                               
          'usuario':usuid,
          'revista':seleccionado.Id,
          'operacion':JSON.stringify(auxArreglo),
          'idDispositivo':'wed4513s1c2s1cds1cd'
          });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: {
                    "access-token": token,
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                data: dataInfo,
                cancelToken: source.token,
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux.success){
                  let mensaje = "Se editaron correctamente las tags";
                  setTipoAdvertencia("success");
                  setMensaje(mensaje);
                  setAviso(true);   
                  setReiniciar(true)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        historial.push("/");                              
                    } 
                }
            })
            setEspera(false)
        }


    function generarDatos(){
        return(
        <TableContainer>
        <Table>
            <TableHead>
                <TableRow >
                    <TableCell padding='none'>
                    </TableCell>
                    <TableCell padding='none'>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
            {   listCategoria.map((categoria,index)=>{
                return(
                    <TableRow className={classes.tableRow} selected={idSelec === categoria.Id}  key={index}
                        classes={{ hover: classes.hover, selected: classes.selected }} onClick={ () => selecFila(categoria,index) }
                    >   
                    <TableCell padding="none" className={classes.tableCell}> 
                        {listaActivos.find(  (registro) => registro.Id === categoria.Id  ) ?
                            (idSelec === categoria.Id ? <Radio checked={true} style={{color:'white'}}/> :<Radio color="primary" checked={true} />)
                        :   (idSelec === categoria.Id ? <Radio checked={false} style={{color:'white'}}/> :<Radio color="primary" checked={false} />)}                                        
                    </TableCell>
                    <TableCell padding="none" className={classes.tableCell}>
                        {categoria.Des}
                    </TableCell>
                    </TableRow>
                )
                })
            }
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const verificarDatos = listCategoria.length!==0 ? generarDatos() : null;
    const generar = esperallamada? <Box style={{width:'100%'}}><LinearProgress/></Box> : verificarDatos;

    return(
        <Modal
        titulo={titulo}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        guardarDatos={handleSubmission}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso}
        tamanio={'md'}
        cargando={espera}    
        actualizar={actualizar}
        setActualizar={setActualizar}
        reiniciar={reiniciar}       
        >
            {generar}
        </Modal>
    )
    

}