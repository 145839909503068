import React from 'react'
import { Box, Button,LinearProgress } from '@material-ui/core'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import {GeneralesContext} from '../context/GeneralesContext'
//import Compra from './Compra'
import TablaCompras from './TablaCompra'

const ClienteInicio = () => {
    let history = useHistory();
    const [actualizar,setActualizar]=React.useState(false);
    const [listCliente, setListCliente] = React.useState([]); 
    const [espera,setEspera]=React.useState(false);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);
    React.useEffect(()=>{
        const source = axios.CancelToken.source(); 
        let idUsu =localStorage.getItem('UsuId') ;     
        let token =localStorage.getItem('token20') ;    
        async function llenaListCliente()  {                       
            setEspera(true)
            let auxU=process.env.REACT_APP_LINK +`/compra-suscripcion-cliente-list`;                                           
            let dataInfo = qs.stringify({     
                'usuario':idUsu  ,            
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;                                 
                if (aux.length !== 0) 
                {   console.log(aux); 
                    setListCliente(aux)                    
                }                                         
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                } 
            }
            });            
            setEspera(false)                
        };
        llenaListCliente()
        guardarGenerales({...generales,Titulo:'Compras'})  
    },[actualizar])

    const llamarActualizar=()=>{
        setActualizar(!actualizar)
    }

    //const modalCompra = <Compra modalAbierto={modalAbierto} setModalAbierto ={setModalAbierto } />

    return (
    <div>        
        <Box>
            <Button onClick={()=>llamarActualizar()} variant="contained" color="secondary" >
                Recuperar Lista
            </Button>
            {espera? <Box pt={3}><LinearProgress/> </Box> :<TablaCompras listUsu={listCliente} />}
        </Box>      
    </div>
    )
}

export default ClienteInicio