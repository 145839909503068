import React , {useState, useEffect} from 'react'
import Modal from '../generales/ModalCerrar';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,Grid,TextField,} from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import axios from 'axios';
import qs from 'qs';
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify'; 
const useStyles = makeStyles((theme) => ({
  root: {
    margin: {
      margin: theme.spacing(1),
    },
  },
  formEncabezado:{
    marginTop:'1.7em',  
  },
  formAnotacion: {
    width:'12em'
  },
  formMinimo: {
    width:'6em'
  },
  formSelect: {
    width:'12em'
  },
  formFecha: {
    marginTop:'.2em',
    width:'10em'
  },
  formDesc:{
    marginTop:'1.7em',
    width:'30em',
    height:'6em',
    color:'white',
    backgroundColor:'#424242'
  },
}));


  export default function NuevaPublicacion({modalAbierto,setModalAbierto,rev,setActualizar,actualizar}){
    let historial = useHistory();   
    const classes = useStyles();
    const [espera,setEspera]=useState(false)
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [reiniciar,setReiniciar] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [nombre,setNombre] = useState("");
    const [numero, setNumero] =useState("")
    const [precio, setPrecio] = useState(0)
    const [issn,setIssn] = useState("");
    const [alias,setAlias] = useState("")
    const [fechaPublicacion, setFechaPublicacion] = useState(moment(new Date()).format('YYYY/MM/DD'))
    let fechaMax = moment(new Date()).add(1,'years')    
    //let fechaMax=moment(fechaMax2).format('YYYY/MM/DD')
    const [desc,setDesc] = useState("");
    const [tokenPubl,setTokenPubl] = useState("")
    const expPermitidaNumeros = /^\d+(\.\d{1,2})?$/;
    const expPermitidaDecimal = /^(\d+\.?\d*|\.\d+)$/;
    const expPermitidaEnteros = new RegExp("^[0-9]+$");
    const exPermitidaNombre = /^([A-Za-z0-9áéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z0-9 _.,ñÑáéíóúÁÉÍÓÚ]*)$/
    const exPermitidaNombrePubli = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]')
    const exPermitidaToken = /^([A-Za-z0-9]+)(?:[A-Za-z0-9]*)$/

    const onChangeNombre = (e) =>{
      if(exPermitidaNombrePubli.test(e.target.value)||e.target.value===' '||e.target.value===''){
        setNombre(e.target.value);
      }
    }
  
    const onChangePrecio = (e) =>{
      let expNopermitida = new RegExp('[#A-Za-z]');
      if(expPermitidaDecimal.test(e.target.value)&&!expNopermitida.test(e.target.value)||e.target.value===' '||e.target.value===''){
      setPrecio(e.target.value);
      }
    }
    
    const onChangeAlias = (e) =>{
      if(exPermitidaNombrePubli.test(e.target.value)||e.target.value===' '||e.target.value===''){
      setAlias(e.target.value)
      }
    }

    const onChangeToken = (e) =>{
      /* if(exPermitidaToken.test(e.target.value)||e.target.value===' '||e.target.value===''){
        setTokenPubl(e.target.value.toUpperCase())
      } */
      setTokenPubl(e.target.value)
    }

    
    const onChangeIssn = (e) =>{ 
      if(exPermitidaToken.test(e.target.value)||e.target.value===' '||e.target.value===''){
          setIssn(e.target.value.toUpperCase());
        }
    }
    

    const onChangeNumero = (e) =>{
      let expNopermitida = new RegExp('[#A-Za-z_:,. $!%-({})/*=?;¿¡´°"|^~\]');
      let expMenos = new RegExp('-');
      let expMas = new RegExp('[+]');   
        if (expPermitidaEnteros.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') {
          setNumero(e.target.value);
        }
    }

    const handleDateChange = (date) => {
      setFechaPublicacion(date)
    };
    const onChangeDesc = e =>
    {  
      let expNopermitida = new RegExp('[{}*|`]');        
      let expMenos = new RegExp("'");
      let expMas = new RegExp('"');             
      let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,.%<>=]');
      if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
          !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
      {
        setDesc(e.target.value);
        if (aviso) 
        { setMensaje('');
          setAviso(false);
        }
      }		
    };
/* 
  const onChangeDesc = (e) =>{
    if(exPermitidaNombrePubli.test(e.target.value)||e.target.value===' '||e.target.value===''){
      setDesc(e.target.value);
    }
  } */
      

  function verificarNombre(nom){
    var validado=true
    var auxNom=""
    if(expPermitidaEnteros.test(nom)||expPermitidaDecimal.test(nom)){
      auxNom=parseInt(nom)
    }else{
      auxNom=nom.trim()
    }
    if(auxNom && auxNom!=" "){
      if(exPermitidaNombrePubli.test(auxNom.toString())){
        validado=true
      }else{
        validado = false;
        setMensaje("El nombre sólo puede incluir letras, dígitos y espacios");
        setAviso(true);
        setTipoAdvertencia("error");
      }
    }else{
      validado = false;
      if(auxNom===0){

        setMensaje("Ingrese nombre válido");
      }
      else{
        
      setMensaje("Ingrese nombre");
      }
      setAviso(true);
      setTipoAdvertencia("error");
    }
    return validado;
  }
  
      
  function verificarPrecio(precio){
    let validado = true;
    if(precio && precio!==" "){
      if(expPermitidaNumeros.test(precio)){
        validado = true;
      }else{
        validado = false;
        setMensaje(`El precio puede incluir únicamente 2 decimales`);
        setAviso(true);
        setTipoAdvertencia("error");
      }
    }
    return validado;
  }
  
    function verificarAlias(al){
      var validado=true
      var auxAl=""
      if(expPermitidaEnteros.test(al)||expPermitidaDecimal.test(al)){
        auxAl=parseInt(al)
      }else{
        auxAl=al.trim()
      }
      if(al && al!=" "){
        if(exPermitidaNombrePubli.test(auxAl.toString())){
          validado=true
        }else{
          validado = false;
          setMensaje("El alias sólo puede incluir letras, dígitos y espacios");
          setAviso(true);
          setTipoAdvertencia("error");
        }
      }else{
        validado = false;
        if(auxAl===0){
          setMensaje("Ingrese alias válido");
        }else{
          setMensaje("Ingrese alias");
        }
        
        setAviso(true);
        setTipoAdvertencia("error");
      }
      return validado;
    }
  
      function verificarToken(tok){
        var validado=true
        var auxTok=""
        if(expPermitidaEnteros.test(tok)){
          auxTok=parseInt(tok)
        }else{
          auxTok=tok.trim()
        }
        if(auxTok && auxTok!=" "){
          if(exPermitidaToken.test(auxTok.toString())){
            validado=true
          }else{
            validado = false;
            setMensaje("El token sólo puede incluir letras y dígitos");
            setAviso(true);
            setTipoAdvertencia("error");
          }
        }else{
          validado = false;
          if(auxTok===0){
            setMensaje("Ingrese token válido");
          }else{
            setMensaje("Ingrese token");
          }
          setAviso(true);
          setTipoAdvertencia("error");
        }
        return validado;
      }
  
  
    function verificarIssn(codigo){
      var validado=true
        var auxCod=""
        if(expPermitidaEnteros.test(codigo)){
          auxCod=parseInt(codigo)
        }else{
          auxCod=codigo.trim()
        }
        if(auxCod && auxCod!=" "){
          if(exPermitidaToken.test(auxCod.toString())){
            validado=true
          }else{
            validado = false;
            setMensaje("El issn sólo puede incluir letras y dígitos");
            setAviso(true);
            setTipoAdvertencia("error");
          }
        }else{
          validado = false;
          if(auxCod===0){
            setMensaje("Ingrese issn válido");
          }else{
            setMensaje("Ingrese issn");
          }
          setAviso(true);
          setTipoAdvertencia("error");
        }
        return validado;
    }
  

    function verificarNumero(num){
      let validado = false;
      if(num && num!=" "){
        if(Number(num)>0){
              if(num.length>0&&num.length<6){
                  validado=true
                }else{
                  validado = false;
                  setMensaje("El número debe contener máximo 5 dígitos");
                  setAviso(true);
                  setTipoAdvertencia("error");
                }
          }else{
              validado = false;
              setMensaje("El número debe ser mayor a 0");
              setAviso(true);
              setTipoAdvertencia("error");
          }
      }else{
        validado = false;
        setMensaje("Ingrese número");
        setAviso(true);
        setTipoAdvertencia("error");
      }
      return validado;
    }
  
  
      function verificarDesc(desc){
          let validado = false;
          var auxDesc=""
          if(expPermitidaEnteros.test(desc)||expPermitidaDecimal.test(desc)){
            auxDesc=parseInt(desc)
          }else{
            auxDesc=desc.trim()
          }
          if(auxDesc && auxDesc!=" "){
            if(exPermitidaNombrePubli.test(auxDesc.toString())){
              validado=true
            }else{
              validado = false;
              setMensaje("La descripción sólo puede incluir letras, dígitos y espacios");
              setAviso(true);
              setTipoAdvertencia("error");
            }
          }else{
            validado = false;
            if(auxDesc===0){
              setMensaje("Ingrese descripción válida");
            }else{
              setMensaje("Ingrese descripción");
            }
            setAviso(true);
            setTipoAdvertencia("error");
          }
          return validado;
        }
  
        function valFecha( ) {
          let respuesta=false
          if (
            
              moment(fechaPublicacion).format('YYYY-MM-DD') !== '1900-01-01'   ) 
          {
              respuesta=true
          }
          else
          {
            setMensaje("Ingrese fecha válida");
            setAviso(true);
            setTipoAdvertencia("error");
          }
          return respuesta
      }

      async function registrarPublicacion(auxFechaPubli, auxFechaReg) {
        setEspera(true);
        setBtnDeshabilitado(true);
        const source = axios.CancelToken.source();
        let auxU = process.env.REACT_APP_LINK + `/publicacion-nuevo`;
        let token = localStorage.getItem("token20");
        let usuid = localStorage.getItem("UsuId");
        let dataInfo = qs.stringify({
          usuario: usuid,
          revista: rev.Id,
          fecha: auxFechaReg,
          fpublica: auxFechaPubli,
          issn: " ",
          numero: numero,
          pagina: 0,
          precio: precio && precio.toString().trim !== "" ? precio : 0,
          nombre: nombre.trim(),
          alias: alias.trim(),
          descr: desc.trim(),
          token: tokenPubl.trim(),
          idDispositivo: "wed4513s1c2s1cds1cd",
        });
        let config = {
          url: auxU,
          method: "POST",
          headers: {
            "access-token": token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: dataInfo,
          cancelToken: source.token,
        };
        let aux = {};
        await axios(config)
          .then(function (response) {
            aux = response.data;
            if (aux.respuesta === 1) {
              let mensaje = "Se agregó correctamente la publicación";
              setNombre("");
              setDesc("");
              setAlias("");
              setIssn("");
              setNumero("");
              setPrecio("");
              setTokenPubl("");
              setTipoAdvertencia("success");
              setMensaje(mensaje);
              setAviso(true);
              setReiniciar(true);
              toast.success(mensaje, {
                pauseOnFocusLoss: false,
                toastId: `exito-nvo-pub${1}`,
              });
            } else {
              let mensaje = "Ocurrió un error, intente más tarde";
              setTipoAdvertencia("warning");
              setMensaje(mensaje);
              setAviso(true);
            }
          })
          .catch(function (error) {
            console.log(`Error: ${error}`);
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.mensaje === "Datos Incorrectos"
            ) {
              console.log("datos incorrectos");
            } else {
              if (
                (error &&
                  error.response &&
                  error.response.data &&
                  error.response.data.error.name === "JsonWebTokenError") ||
                (error &&
                  error.response &&
                  error.response.data &&
                  error.response.data.error.name === "TokenExpiredError")
              ) {
                console.log("loguearse de nuevo");
                localStorage.clear();
                historial.push("/");
              }
            }
          });
        setEspera(false);
        setBtnDeshabilitado(false);
      }

    
    const handleSubmission = ()=>{
      if(verificarNombre(nombre)){//se quito la validacion del token y el issn
        if(verificarPrecio(precio)&&verificarAlias(alias)&& verificarNumero(numero)&&valFecha() 
          &&verificarDesc(desc)){
          let nuevaFecha = new Date();
          let fec = moment(nuevaFecha).format();   
          let fecAux = fec.substring(0,fec.lastIndexOf('-'))  
          let fechaPubli = moment(fechaPublicacion).format();  
          let fecAuxPubli = fechaPubli.substring(0,fechaPubli.lastIndexOf('-'))
          registrarPublicacion(fecAuxPubli,fecAux)
        }
      } 
    };


  return(
  <Modal
    titulo={"Nueva Publicación"} modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={handleSubmission}
    mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
    aviso={aviso} btnDeshabilitado={btnDeshabilitado}
    setAviso={setAviso} tamanio={'md'} cargando={espera}
    actualizar={actualizar} setActualizar={setActualizar}
    reiniciar={reiniciar}          
  >
    <form className={classes.root} noValidate autoComplete="off"> 
      <Grid container spacing={1}>
        <Grid item>
          <FormControl className={classes.formAnotacion}>
            <TextField autoFocus required label="Nombre" color='secondary' name="nombre"
              value={nombre} onChange={onChangeNombre}id={'nombre'}
            /> 
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formMinimo}>
            <TextField required label="Precio" color='secondary' name="precio"
              value={precio} onChange={onChangePrecio}id={'precio'}
            /> 
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formFecha}>
            <TextField required label="Alias" color='secondary' name="alias"
              value={alias} onChange={onChangeAlias}id={'alias'}
            /> 
          </FormControl>
        </Grid>
      </Grid>
        <Grid container spacing={1}>
          <Grid item>
            <FormControl className={classes.formAnotacion}>
              {/* <TextField required label="ISSN" color='secondary' name="issn"
                value={issn} onChange={onChangeIssn}id={'issn'}
              />  */}
              <TextField required label="Token" color='secondary' name="tokenPubl"
                value={tokenPubl} onChange={onChangeToken} id={'tokenPubl'}
              /> 
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formMinimo}>
              <TextField required label="Número" color='secondary' name="numero"
                value={numero} onChange={onChangeNumero}id={'numero'}
              /> 
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formFecha}>
              <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                <DatePicker
                  format="yyyy/MM/dd" views={["year", "month", "date"]}
                  label="Fecha Publicación" size="small" name="fechaPublicacion"
                  openTo="year" cancelLabel={"Cancelar"} color='secondary'                  
                  okLabel="Seleccionar"     minDate={'1900/01/01'}
                  maxDate={fechaMax}
                  value={fechaPublicacion} onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Grid container spacing={1}>            
          <Grid item xs={1}>
            <FormControl className={classes.formAnotacion}>
              <TextField required label="Token" color='secondary' name="tokenPubl"
                value={tokenPubl} onChange={onChangeToken}id={'tokenPubl'}
              /> 
            </FormControl>
          </Grid>
        </Grid> */}
        <textarea className={classes.formDesc} placeholder="Descripción"
          value={desc} onChange={onChangeDesc} id={'desc'} name="desc"
          multiline rows={2} maxRows={4} style={{marginBottom:'.5em'}}
        />
      </form>
    </Modal>
  )
}