import React , {useState, useEffect} from 'react'
import Modal from '../generales/ModalCerrar';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,Grid,FormControlLabel,FormLabel, RadioGroup, TextField,Radio} from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import axios from 'axios';
import qs from 'qs';
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify'; 
const useStyles = makeStyles((theme) => ({
  root: {
    margin: {
      margin: theme.spacing(1),
    },
  },
  formEncabezado:{
    marginTop:'1.7em',  
  },
  formAnotacion: {
    width:'12em',
    
    marginRight:'.5rem'
  },
  formMinimo: {
    width:'6em',
    marginRight:'.5rem'
  },
  formSelect: {
    width:'12em'
  },
  formFecha: {
    marginTop:'.2em',
    width:'10em'
  },
  formDesc:{
    marginTop:'1 rem',
    width:'30em',
    height:'6em',
    color:'white',
    backgroundColor:'#424242'
  },
}));


  export default function EditarPublicacion({modalAbierto,setModalAbierto,rev,seleccionado,titulo,actualizar,setActualizar}){
    let historial = useHistory();   
    const classes = useStyles();
    const [registro, setRegistro] = useState({nombre:'',
        issn:'',
        alias:'',
        tokenPubl:'',
        numero:'',
        pagina:'', precio:'',
        fechaPublicacion:new Date(),
        desc:'',status:1})
    const [espera,setEspera]=useState(false)
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);
    const [editActivo, setEditActivo] = useState("activo");
    const [reiniciar,setReiniciar] = useState(false)
    const expPermitidaNumeros = /^\d+(\.\d{1,2})?$/;
    const expPermitidaDecimal = /^(\d+\.?\d*|\.\d+)$/;
    const expPermitidaEnteros = new RegExp("^[0-9]+$");
    const exPermitidaNombre = /^([A-Za-z0-9áéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z0-9 _.,ñÑáéíóúÁÉÍÓÚ]*)$/
    const exPermitidaNombrePubli = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
    //new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]')
    const exPermitidaToken = /^([A-Za-z0-9.]+)(?:[A-Za-z0-9]*)$/
    let fechaMax = moment(new Date()).add(1,'years')
    const {nombre,desc,issn,alias,tokenPubl,numero,pagina,fechaPublicacion,precio,status}=registro;

    useEffect(()=>{
      setRegistro({
        ...registro,
        nombre:seleccionado.Nombre,
        issn:seleccionado.Issn,
        alias:seleccionado.Alias,
        tokenPubl:seleccionado.Token,
        numero:seleccionado.Num,
        pagina:seleccionado.Pagina, precio:seleccionado.Precio,
        fechaPublicacion:moment().format(seleccionado.FPublica),
        //fechaPublicacion: moment(seleccionado.FPublica).add(1, 'days'),
        desc:seleccionado.Descr,status:1})
    },[seleccionado])
    
  
    const onChangePrecio = (e) =>{
      let expNopermitida = new RegExp('[#A-Za-z]');
      if(expPermitidaDecimal.test(e.target.value)&&!expNopermitida.test(e.target.value)||e.target.value===' '||e.target.value===''){
        setRegistro({
          ...registro,
          precio: e.target.value});
      }
    }
    
    const onChangeToken = (e) =>{
      /* if(exPermitidaToken.test(e.target.value)||e.target.value===' '||e.target.value===''){
        setRegistro({
          ...registro,
          //tokenPubl: e.target.value.toUpperCase()
          tokenPubl: e.target.value
        });
      } */
      setRegistro({
        ...registro,
        //tokenPubl: e.target.value.toUpperCase()
        tokenPubl: e.target.value
      });
    }

    
    const onChangeIssn = (e) =>{
      if(exPermitidaToken.test(e.target.value)||e.target.value===' '||e.target.value===''){
        setRegistro({
          ...registro,
          issn: e.target.value.toUpperCase()});
      }
    }
    
    


    const onChange = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[.%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
                 setRegistro({
            ...registro, //copiamos el nvocliente
            [e.target.name] : e.target.value 
        })       
        }		
	};   

    const onChangeNumero = (e) =>{
      let expNopermitida = new RegExp('[#A-Za-z_:,. $!%-({})/*=?;¿¡´°"|^~\]');
        let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');   
        if (expPermitidaEnteros.test(e.target.value)&&!expNopermitida.test(e.target.value)&&!expMenos.test(e.target.value)&&
        !expMas.test(e.target.value)||e.target.value===' '||e.target.value==='') {
          setRegistro({
            ...registro,
            numero: e.target.value});
        }
    }

    const handleDateChange = (date) => {
      setRegistro({
        ...registro,
        fechaPublicacion: date});
    };

      const onChangeActivo = (event) => {
        event.preventDefault();
        setEditActivo(event.target.value);
        switch(event.target.value){
          case "activo":
            setRegistro({
              ...registro,
              status: 1,
            });
          break;
          case "inactivo":
            setRegistro({
              ...registro,
              status: 0,
            });
          break;
        }
      };

      function verificarNombre(nom){
        var validado=true
        var auxNom=""
        if(expPermitidaEnteros.test(nom)||expPermitidaDecimal.test(nom)){
          auxNom=parseInt(nom)
        }else{
          auxNom=nom.trim()
        }
        if(auxNom && auxNom!=" "){
          if(exPermitidaNombrePubli.test(auxNom.toString())){
            validado=true
          }else{
            validado = false;
            setMensaje("El nombre sólo puede incluir letras, dígitos y espacios");
            setAviso(true);
            setTipoAdvertencia("error");
          }
        }else{
          validado = false;
          if(auxNom===0){

            setMensaje("Ingrese nombre válido");
          }
          else{
            
          setMensaje("Ingrese nombre");
          }
          setAviso(true);
          setTipoAdvertencia("error");
        }
        return validado;
      }
  
      
      function verificarPrecio(precio){
        let validado = true;
        if(precio && precio!==" "){
          if(expPermitidaNumeros.test(precio)){
            validado = true;
          }else{
            validado = false;
            setMensaje(`El precio puede incluir únicamente 2 decimales`);
            setAviso(true);
            setTipoAdvertencia("error");
          }
        }
        return validado;
      }
  
      function verificarAlias(al){
        var validado=true
        var auxAl=""
        if(expPermitidaEnteros.test(al)||expPermitidaDecimal.test(al)){
          auxAl=parseInt(al)
        }else{
          auxAl=al.trim()
        }
        if(al && al!=" "){
          if(exPermitidaNombrePubli.test(auxAl.toString())){
            validado=true
          }else{
            validado = false;
            setMensaje("El alias sólo puede incluir letras, dígitos y espacios");
            setAviso(true);
            setTipoAdvertencia("error");
          }
        }else{
          validado = false;
          if(auxAl===0){
            setMensaje("Ingrese alias válido");
          }else{
            setMensaje("Ingrese alias");
          }
          
          setAviso(true);
          setTipoAdvertencia("error");
        }
        return validado;
      }
  
  function verificarToken(tok){
    var validado=true
    var auxTok=""
    if(expPermitidaEnteros.test(tok)){
      auxTok=parseInt(tok)
    }else{
      auxTok=tok.trim()
    }
    if(auxTok && auxTok!=" "){
      if(exPermitidaToken.test(auxTok.toString())){
        validado=true
      }else{
        validado = false;
        setMensaje("El token sólo puede incluir letras y dígitos");
        setAviso(true);
        setTipoAdvertencia("error");
      }
    }else{
      validado = false;
      if(auxTok===0){
        setMensaje("Ingrese token válido");
      }else{
        setMensaje("Ingrese token");
      }
      setAviso(true);
      setTipoAdvertencia("error");
    }
    return validado;
  }
      
  function verificarIssn(codigo){
    var validado=true
    var auxCod=""
    if(expPermitidaEnteros.test(codigo))
    { auxCod=parseInt(codigo)
    }
    else
    { auxCod=codigo.trim()
    }
    if(auxCod && auxCod!=" ")
    { if(exPermitidaToken.test(auxCod.toString()))
      { validado=true
      }
      else
      { validado = false;
        setMensaje("El issn sólo puede incluir letras y dígitos");
        setAviso(true);
        setTipoAdvertencia("error");
      }
    }
    else
    { validado = false;
      if(auxCod===0)
      { setMensaje("Ingrese issn válido");
      }
      else
      { setMensaje("Ingrese issn");
      }
      setAviso(true);
      setTipoAdvertencia("error");
    }
    return validado;
  }
  

  function verificarNumero(num){
    let validado = false;
    if(num && num!=" ")
    { if(Number(num)>0)
      { if(num.length>0&&num.length<6)
        { validado=true
        }
        else
        { validado = false;
          setMensaje("El número debe contener máximo 5 dígitos");
          setAviso(true);
          setTipoAdvertencia("error");
        }
      }
      else
      { validado = false;
        setMensaje("El número debe ser mayor a 0");
        setAviso(true);
        setTipoAdvertencia("error");
      }
    }
    else
    { validado = false;
      setMensaje("Ingrese número");
      setAviso(true);
      setTipoAdvertencia("error");
    }
    return validado;
  }
  
  
  function verificarDesc(desc){
    let validado = false;
    var auxDesc=""
    if(expPermitidaEnteros.test(desc)||expPermitidaDecimal.test(desc))
    {  auxDesc=parseInt(desc)
    }
    else
    { auxDesc=desc.trim()
    }
    if(auxDesc && auxDesc!=" ")
    { if(exPermitidaNombrePubli.test(auxDesc.toString()))
      { validado=true
      }
      else
      { validado = false;
        setMensaje("La descripción sólo puede incluir letras, dígitos y espacios");
        setAviso(true);
        setTipoAdvertencia("error");
      }
    }
    else
    { validado = false;
      if(auxDesc===0)
      { setMensaje("Ingrese descripción válida");
      }
      else
      { setMensaje("Ingrese descripción");
      }
      setAviso(true);
      setTipoAdvertencia("error");
    }
    return validado;
  }
  
  function valFecha( ) {
    let respuesta=false
    if ( moment(fechaPublicacion).format('YYYY-MM-DD') !== '1900-01-01'   ) 
    {
      respuesta=true
    }
    else
    { setMensaje("Ingrese fecha válida");
      setAviso(true);
      setTipoAdvertencia("error");
    }
    return respuesta
  }

  async function editarPublicacion(auxFechaPubli,auxFechaReg){
    setEspera(true)
    setBtnDeshabilitado(true)
    const source = axios.CancelToken.source();    
    let auxU=process.env.REACT_APP_LINK +`/publicacion-editar`;  
    let token = localStorage.getItem("token20");
    let usuid = localStorage.getItem('UsuId') 
    let dataInfo = qs.stringify({                               
      'usuario':usuid,
      'publicacion':seleccionado.Id,
      'revista':rev.Id,
      'fecha':auxFechaReg,
      'fpublica':auxFechaPubli,
      'issn':" ",//issn.trim(),
      'numero':numero,
      'pagina':pagina,
      'precio':precio && precio.toString().trim!=="" ? precio : 0,
      'nombre':nombre.trim(),
      'alias':alias.trim(),
      'descr':desc.trim(),
      'token':tokenPubl.trim(),
      'status':status,
      'idDispositivo':'wed4513s1c2s1cds1cd'
      });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: {
                "access-token": token,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            data: dataInfo,
            cancelToken: source.token,
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {          
            aux=  response.data;    
            if(aux.respuesta===1){
              let mensaje = "Se editó correctamente la publicación.";
              setTipoAdvertencia("success");
              setMensaje(mensaje);
              setAviso(true);   
              setReiniciar(true)
              setModalAbierto(false)
              toast.success(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `exito-edit-pub${1}`
              })
            }else{
              let mensaje = "Ocurrió un error, intente más tarde";
              toast.warning(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `fallo-edit-pub${1}`
              })
              setTipoAdvertencia("warning");
              setMensaje(mensaje);
              setAviso(true);   
            }
        }).catch(function (error) {          
            console.log(`Error: ${error}`);        
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    historial.push("/");                              
                } 
            }
        })
        setEspera(false)
  }

    
  const handleSubmission = (e)=>{
    if(verificarNombre(nombre)){//se quito la validacion del token y el issn
      if(verificarPrecio(precio.toString())&&verificarAlias(alias)
        && verificarNumero(numero.toString())&&valFecha()&&verificarDesc(desc)){
        let nuevaFecha = new Date();
        let fec = moment(nuevaFecha).format();   
        let fecAux = fec.substring(0,fec.lastIndexOf('-'))  
        let fechaPubli = moment(fechaPublicacion).format();  
        let fecAuxPubli = fechaPubli.substring(0,fechaPubli.lastIndexOf('-'))
        editarPublicacion(fecAuxPubli,fecAux)
      }
    } 
  };
  
  const onChangeDesc = e =>
	{  
    let expNopermitida = new RegExp('[{}*|`]');        
    let expMenos = new RegExp("'");
    let expMas = new RegExp('"');             
    let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,.%<>=]');
    if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && 
        !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
    {
      setRegistro({
        ...registro, 
        [e.target.name] : e.target.value 
      })

      if (aviso) 
      { setMensaje('');
        setAviso(false);
      }
    }		
	};


  return (
    <Modal
      titulo={titulo}
      modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto}
      guardarDatos={handleSubmission}
      mensaje={mensaje}
      tipoAdvertencia={tipoAdvertencia}
      aviso={aviso}
      btnDeshabilitado={btnDeshabilitado}
      setAviso={setAviso}
      tamanio={"md"}
      cargando={espera}
      actualizar={actualizar}
      setActualizar={setActualizar}
      reiniciar={reiniciar}
    >
      <div>
        <TextField autoFocus
          required label="Nombre" className={classes.formAnotacion}
          name="nombre" value={nombre} onChange={onChange}
          id={"nombre"}
        />
        <TextField
          required label="Precio" className={classes.formMinimo}
          color="secondary" key={"precio"} value={precio}
          onChange={onChangePrecio} id={"precio"}
        />
        <TextField
          required label="Alias" className={classes.formFecha}
          name="alias" color="secondary" key="alias"
          value={alias} onChange={onChange} id={"alias"}
        />
        <br />
        {/* <TextField required label="ISSN" className={classes.formAnotacion} name="issn"  color='secondary' key="issn"
      value={issn} onChange={onChangeIssn}id={'issn'}/>  */}
        <TextField
          required label="Token PlayStore" className={classes.formAnotacion}
          color="secondary" key="tokenPubl" name="tokenPubl"
          value={tokenPubl} onChange={onChangeToken}
          id={"tokenPubl"}
        />
        <TextField
          required label="Número" className={classes.formMinimo}
          name="numero" color="secondary" key="numero"
          value={numero} onChange={onChangeNumero}
          id={"numero"}
        />
        <FormControl className={classes.formFecha}>
          <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
            <DatePicker
              format="yyyy/MM/dd" views={["year", "month", "date"]}
              label="Fecha Publicación" size="small"
              name="fechaPublicacion" openTo="year"
              cancelLabel={"Cancelar"} okLabel="Seleccionar"
              color="secondary" minDate={"1900/01/01"}
              maxDate={fechaMax} value={fechaPublicacion}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
        <br />
        {/**/}
        <br />
        <textarea
          className={classes.formDesc} placeholder="Descripción"
          value={desc} onChange={onChangeDesc} id={"desc"}
          name="desc" key="desc" multiline rows={2}
          maxRows={4} style={{ marginBottom: ".5em" }}
        />
        <Grid container spacing={2}>
          <Grid item>
            <FormControl className={classes.campos}>
              <FormLabel>Visualizar</FormLabel>
              <RadioGroup
                aria-label="activo"
                defaultValue={editActivo}
                onChange={onChangeActivo}
                name="Activo"
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="activo" control={<Radio />}
                      label="Si" style={{ color: "#F96550" }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <FormControlLabel
                      value="inactivo" control={<Radio />}
                      label="No" style={{ color: "#F96550" }}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
  }