import React,{useState, useEffect} from 'react'
export default function ArchivoVisualizador({archivo,publiNombre}){
    let altuTamMax=600

    const [alturaContenedor, setAlturaContenedor] = useState (window.innerHeight<altuTamMax ? window.innerHeight-310 : window.innerHeight - 210)

    useEffect(()=>{
        //console.log(archivo)
        window.addEventListener('resize', function() {
            if(window.innerHeight<altuTamMax){
                setAlturaContenedor(window.innerHeight-310)
            }else{
                setAlturaContenedor(window.innerHeight-210)
            }
        });  
    },[alturaContenedor,archivo])

    function generarVisualizador(){
        let elemento=<div></div>
                if(archivo.Dir && archivo.Img && archivo.Link )
                {
                    elemento=
                    <object data={archivo.Dir + archivo.Img+archivo.Link} type="application/pdf" style={{width:48+'rem'}} height={alturaContenedor}>
                        
                    </object>
                }else{
                    elemento= <object data={archivo} type="application/pdf" style={{width:48+'rem'}}height={alturaContenedor}></object>
                }
        return elemento;
    }

    const revistasMostrar = archivo.length!=0 ? generarVisualizador() :null;
    
    return(
    <div style={{maxHeight:alturaContenedor, width:600}}>
        {revistasMostrar}
    </div>
    )
}