import React from 'react'

const PaginaError = () => {
  return (
    <div style={{display:"flex",justifyContent:"center", height:"80vh"}}>
        <img src="./error404.jpeg"    />
    </div>
  )
}

export default PaginaError