import React,{useState} from 'react'
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,
        //Collapse,Paper,Button,Card,
        TableContainer,TableHead,TableRow,Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'; 
import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
//import { useHistory } from "react-router-dom";
//import axios from 'axios';
//import qs from 'qs';
//import InfoCliente from '../clientes/InfoCliente';
//import {AuthContext} from '../context/AuthContext'
//import {GeneralesContext} from '../context/GeneralesContext'
//import { authUser } from '../funciones/AuthUser'
//import EditarStatus from './EditarStatusUsu'
import moment from 'moment';
import EditarUsuario from './EditarUsuario'
import NvoUsuario from './NuevoUsuario'

const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:800,
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
       
    {
      id:1,
      titulo:"Usuario",                                                
      alinear:'center'
    },    
    {
      id:2,
      titulo:"Email",                                                
      alinear:'center'
    },   
    {
      id:3,
      titulo:"Tipo",                                                
      alinear:'center'
    },    
    {
      id:4,
      titulo:"Alta",                                                
      alinear:'center'
    },        
  ];
const TablaUsuarios = ({listUsu,auxActualizar,actualizar,setActualizar,listTipoUsu,btnCargar }) => {
    const classes = useStyles();
    const [datos, setDatos] = React.useState([])  
    const[sinDatos,guardarSinDatos]= React.useState(true)
    const [espera,setEspera]=React.useState(false);
    const [selectedID, setSelectedID] = React.useState(null);
    //const [open, setOpen] = React.useState(false);    
    //const [ordenSelecc, setOrdenSelecc] = React.useState(0);
    //const [modalProcAbierto, setModalProcAbierto] = React.useState(false);
    //const [modalInfoCliAbierto, setModalInfoCliAbierto] = React.useState(false);
    //const [tituloProcCli, setTituloProcCli] = React.useState("");
    //const [tituloInfoCli, setTituloInfoCli] = React.useState("");
    const [clienteSeleccionado,setClienteSeleccionado]= useState([]);    
    const [modalEditCliAbierto,setModalEditCliAbierto]= useState(false); 
    const [modalNvoUsrAbierto,setModalNvoUsrAbierto]= useState(false); 
    //const [modalEditStdUsu,setModalEditStdUsu]= useState(false); 
    //const[actualizar,setActualizar]=useState(true);
    //const {usuario,guardarUsuario}=React.useContext(AuthContext);
    //const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    //let history = useHistory();     
    //const[auxCliente,setAuxCliente]=React.useState([])    
    
    React.useEffect(()=>
    {
      
      setDatos(listUsu)        
               
    },[listUsu])

  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)   
  }
   
  function abrirModalEditCliente(auxClient)
  { setClienteSeleccionado(auxClient)    
    setModalEditCliAbierto(true);    
  }

  function abrirModalNvoUsr()
  { setModalNvoUsrAbierto(true);    
  }
   
  function Renglon(props)
  {
    const { row } = props;  
    //let auxColorStd=row.Status===1 ? "#0158A5":"#d40100";        
    return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        //onContextMenu={(event)=>guadarPosCursor(event,row)} 
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >         
       {/*   
       */}
       <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          <Tooltip title={`Editar Usuario`}>
            <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditCliente(row)} component="span">
              {selectedID === row.Id ? 
                <CreateIcon style={{color:'white',width:18+'px'}} />
                :<CreateIcon style={{width:18+'px'}} color="secondary"/>
              }
            </IconButton>                            
          </Tooltip>                                                                                                                                              
        </TableCell> 
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:50+'px', paddingRight:2+'px'}} >
          {row.Id}
        </TableCell>     
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:250+'px',fontSize:12+'px'}} >
          {row.Nombre+" "+row.Paterno+" "+row.Materno}
        </TableCell>          
        <TableCell  className={classes.tableCell} align="right" padding={'none'} style={{ width:120+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
          {row.Email}              
        </TableCell>
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:80+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
          {row.TipoNom}              
        </TableCell>         
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}} >          
          {moment.utc( row.FAlta).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( row.FAlta).format('DD/MM/YYYY HH:mm')}       
        </TableCell>              
                         
      </TableRow>        
    </React.Fragment>
  );}
  function tablaCompleta(auxlista){
  //console.log("entrando sin preguntar");
  let colorLetra="#F96550"
  return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>     
            <TableCell style={{ color:colorLetra}} 
              key={1}    align={'center'} padding={'none'} 
            >
              <Tooltip title={`Actualizar lista de Usuarios`}>
                <IconButton aria-label="agregar nuevo" onClick={() => btnCargar()}  
                    component="span" size="small" 
                >
                  <RefreshIcon color="secondary"  />  
                </IconButton>
              </Tooltip>     
              
            </TableCell>                                
            <TableCell style={{ color:colorLetra}} 
              key={1}    align={'center'} padding={'none'} 
            >
              <Tooltip title={`Crear Usuario`}>
                <IconButton aria-label="agregar nuevo" onClick={() => abrirModalNvoUsr()} 
                    component="span" size="small" 
                >
                  <AddCircleOutlineIcon color="secondary"  />  
                </IconButton>
              </Tooltip>              
              <span style={{ verticalAlign: 'middle'}}>
                {auxlista.length}              
              </span>              
            </TableCell>                   
            {columnas.map(row => {              
              return(  
                <TableCell style={{ color:colorLetra}} 
                  key={row.id}    align={row.alinear} padding={'none'} 
                >
                  {row.titulo}
                </TableCell>    
              )                
            })}           
          </TableRow>
        </TableHead>
        <TableBody>
            {auxlista.length!=0 ? (auxlista.map((row) => (
                <Renglon key={row.Id} row={row} />
            ))):null}
        </TableBody>
      </Table>
    </TableContainer>
  )}
    const tablaSinDatos=()=>{  
    return(
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            <TableCell  
              key={1}    align={'center'} padding={'none'} 
            >
              <Tooltip title={`Crear Usuario`}>
                <IconButton aria-label="agregar nuevo" onClick={() => abrirModalNvoUsr()} 
                  component="span" size="small" 
                >
                  <AddCircleOutlineIcon color="secondary"  />  
                </IconButton>
              </Tooltip>              
            </TableCell>
            {columnas.map(row => {              
              return(  
              <TableCell style={{ backgroundColor:'#DF0006',color:'white'}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
              )                
            })}                                                
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow 
          className={classes.tableRow}
        > 
          <TableCell  className={classes.tableCell} align="center"> </TableCell>         
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>          
          <TableCell  className={classes.tableCell} align="left">Usuarios</TableCell>             
          <TableCell  className={classes.tableCell} align="center"></TableCell>
          <TableCell  className={classes.tableCell} align="left">Registrado</TableCell>   
          <TableCell  className={classes.tableCell} align="center"></TableCell>                    
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    )}
     
  const contenido = sinDatos&&datos.length!=0 ?tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 
/*   const modInfoClie = modalInfoCliAbierto ? 
                                        ( <InfoCliente modalAbierto={modalInfoCliAbierto}  setModalAbierto={setModalInfoCliAbierto} 
                                            cliente={clienteSeleccionado}  titulo={tituloInfoCli}
                                          />
                                        ): null;   */
  
  const modEditCli = modalEditCliAbierto ? 
                      ( <EditarUsuario modalAbierto={modalEditCliAbierto}  
                          setModalAbierto={setModalEditCliAbierto} usrSelecc={clienteSeleccionado}
                          titulo={'Editar '+clienteSeleccionado.Nombre }                   
                           listTipoUsu ={listTipoUsu } btnCargar={btnCargar}
                        />
                      ): null;   
 /*  const modEditStdUsu = modalEditStdUsu ? 
                      ( <EditarStatus modalAbierto={modalEditStdUsu}  
                          setModalAbierto={setModalEditStdUsu} usrSelecc={clienteSeleccionado}
                          titulo={'Editar Usuario'  }                          
                          setActualizar={setActualizar} actualizar={auxActualizar} 
                        />
                      ): null;   */  
  const modNvoUsr = modalNvoUsrAbierto ? 
                      ( <NvoUsuario modalAbierto={modalNvoUsrAbierto}  
                          setModalAbierto={setModalNvoUsrAbierto} titulo={'Nuevo Usuario'}
                          setActualizar={setActualizar} actualizar={actualizar}
                          listTipoUsu={listTipoUsu}  btnCargar={btnCargar}
                      />
                      ): null;                                                                                                                                                   
  return (
    <div style={{minWidth:40+'rem',marginTop:.5+'rem',maxWidth:43+'rem'}}>        
      {tabla}
      {modNvoUsr}
      {modEditCli}
    </div>
  )
}

export default TablaUsuarios
