import React from 'react';
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles'
import Tema from './componentes/layout/Tema'
import AuthProvider  from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import ClienteProvider from './componentes/context/ClienteContext';
import Contenedor from './componentes/layout/Contenedor'
import Login from './componentes/auth/Login'
import { ToastContainer } from 'react-toastify';  
import RevistaInicio from './componentes/revista/RevistaInicio';
import PublicacionInicio from './componentes/publicacion/PublicacionInicio';
import Usuarios from './componentes/usuario/UsuarioInicio'
import CategoriaInicio from './componentes/categoria/CategoriaInicio';
import ClienteCompra from './componentes/cliente/ClienteInicio'
import Miniatura from './componentes/Miniatura-img';
import PaginaError from './componentes/generales/PaginaError';
//import VisorPdf from './componentes/VisorPdf';
/* import VersionInicio from './componentes/publicacionversion/VersionInicio'; */
//import SuscripcionInicio from './componentes/suscripcion/SuscripcionInicio';
//import NotificacionInicio from './componentes/notificacion/NotificacionInicio';
function App() {
  return (
    <React.Fragment >
      <AuthProvider>
        <GeneralesProvider>
          <ClienteProvider>
          <Router>
            <ThemeProvider theme={Tema}>
              <Contenedor>              
                <Switch>
                  <Route exact path="/" component={Login}  />          
                  {/* <Route exact path="/suscripciones" component={SuscripcionInicio}/> 
                  <Route exact path="/notificacion" component={NotificacionInicio}/>  */}
                  <Route exact path="/revista" component={RevistaInicio}/> 
                  <Route exact path="/publicacion" component={PublicacionInicio}/>
                  <Route exact path="/compra-simulada" component={ClienteCompra}/>
                  <Route exact path="/usuario" component={Usuarios}/>
                  <Route exact path="/categorias" component={CategoriaInicio}/>        
                  <Route exact path="/miniatura-img" component={Miniatura}/> 
                  <Route path="*" component={  PaginaError } /> 
                  {/* <Route exact path="/prb-visor" component={VisorPdf}/>  */}       
                  {/*<Route exact path="/version" component={VersionInicio}/>  */}
                </Switch>              
              </Contenedor>
            </ThemeProvider>
            <ToastContainer autoClose={5000} closeOnClick/>
          </Router>
          </ClienteProvider>
        </GeneralesProvider>
      </AuthProvider>
    </React.Fragment>
  );
}

export default App;