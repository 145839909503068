import React from 'react';
import { Card, CardMedia} from '@material-ui/core';
const CardImgPortada = ({seleccionado}) => {
   /*  React.useEffect(()=>{
        console.log(seleccionado);
    }) */
    return( 
    <>
        <Card style={{width:15+'rem'}}>
            {seleccionado.Img!=="" ?<CardMedia //style={{width:"10rem"}}
                component="img" alt="Portada Revista Magazine"
                height="200" title="Portada Revista Magazine"
                image={seleccionado.Dir+seleccionado.Img} 
            />:
            <CardMedia 
                style={{height:"12rem",width:"13rem",paddingLeft:1.8+'rem',paddingTop:.5+'rem',paddingBottom:.5+'rem'}}
                component="img" alt="Portada Revista Magazine"
                title="Portada Revista Magazine"
                image={"/T_LOGO_1.svg"} 
            />
        }
        </Card>
    </>
    );
};

export default CardImgPortada;
