import React,{useEffect,useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import {  IconButton,Box,Tooltip,LinearProgress  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TablaUsuario from './TablaUsuarios'
//import NvoUsuario from './NuevoUsuario'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
const useStyles = makeStyles({    
    buscador:{
    width:'30em'
    }, 
});

const UsuarioInicio = () => {
    let history = useHistory();
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
   // let history = useHistory();  
    const[usrBusq,setUsrBusq]=React.useState({nombre:''});
    const[listUsu,setListUsu]=React.useState([]);
    const[espera,setEspera]=React.useState(false);
    const{nombre}=usrBusq
    const classes = useStyles();
    const[actualizar,setActualizar]=useState(false);
   // const [modalNvoUsrAbierto,setModalNvoUsrAbierto]= useState(false); 
    //const [modalNvoCliAbierto,setModalNvoCliAbierto]= useState(false);
    const [listTipoUsu, setListTipoUsu] = React.useState([]); 
    const source = axios.CancelToken.source();  
    useEffect(() => {
        let idUsu =localStorage.getItem('UsuId') ;     
        async function llenaListTipo()  {                       
            let auxU=process.env.REACT_APP_LINK +`/usuario-tipo-list`;       
            let token =localStorage.getItem('token20') ;                             
            let dataInfo = qs.stringify({     
                'usuario':idUsu  ,            
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;    
                //console.log(aux);                
                if (aux.length !== 0) 
                {    
                    setListTipoUsu(aux)                    
                }                                         
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                } 
            }
            });                            
        };

        async function llamadaListUsu()  {       
            setEspera(true) ;
            const source = axios.CancelToken.source(); 
            let auxU= process.env.REACT_APP_LINK +`/usuario-list`;               
            let token =localStorage.getItem('token20') ;               
            let dataInfo = qs.stringify({                                 
                'usuario':idUsu,                
                'idDispositivo':'ifrekvjkxcdwdcwe'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },            
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {                
                aux=  response.data;      
                //console.log(aux);                     
                if (aux[0].Id != -1) 
                { // console.log(aux);  
                  //guardarSinDatos(true)                                                           
                  setListUsu(aux)
                }
                else
                { 
                  //guardarSinDatos(false)       
                }                                            
            })
            .catch(function (error) {                
              console.log(error);        
              if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
              {
                console.log('datos incorrectos' );  
              }else
              {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                } 
              }
            });              
            setEspera(false)  ;
        };
      
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            if(!auxValidacion   )
            {   guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {   if (usuario.length==0) 
                {   guardarUsuario({Nombre:'Sistemas',nivel:1});                                                             
                }        
                //document.title = 'Listado Usuarios'                                 
                guardarGenerales({...generales,Titulo:'Lista de Usuarios'})   

                if (listTipoUsu.length===0) 
                {
                    llenaListTipo()                                                        
                }
                llamadaListUsu()
            }            
        }      
          
        veriSesion();	   
        
    }, [actualizar])

       
    
    const btnCargar =()=>{
        setActualizar(!actualizar)        
    } 
    
 
   /*  function abrirModalNvoUsr(){  
        //console.log("abriendo modal");  
        setModalNvoUsrAbierto(true);    
      }
 
    const modNvoUsr = modalNvoUsrAbierto ? 
                        ( <NvoUsuario modalAbierto={modalNvoUsrAbierto}  
                            setModalAbierto={setModalNvoUsrAbierto} titulo={'Nuevo Usuario'}
                            setActualizar={setActualizar} actualizar={actualizar}
                            listTipoUsu={listTipoUsu}  
                        />
                        ): null;  */
    const barraCarga = espera ? <Box pt={3}> <LinearProgress/>  </Box>  :null                   
    return (
    <Box ml={1}>
       {/*  <TextField className={classes.buscador} color="secondary"
            name="nombre" placeholder="Usuario" value={nombre} 
            onChange={onChange}
        /> */}
        {/* <Tooltip title={`Actualizar lista usuarios`}>
            <IconButton aria-label="actualizar"  onClick={() => btnCargar()} 
                component="span" size="small" 
            >
                <RefreshIcon color="secondary" />
            </IconButton>
        </Tooltip>  */}       
      {/*   <Tooltip title={`Crear Usuario`}>
            <IconButton aria-label="agregar nuevo" onClick={() => abrirModalNvoUsr()} 
                component="span" size="small" 
            >
                <AddCircleOutlineIcon color="secondary"  />  
            </IconButton>
        </Tooltip> */}
        <br/> 
        {listUsu.length>0 && !espera ? <TablaUsuario auxNombre={nombre} listUsu={listUsu} 
                                        auxActualizar={actualizar} setActualizar={setActualizar}
                                        listTipoUsu={listTipoUsu}  btnCargar={btnCargar}
                                    />
        :null }
        {barraCarga }
      
    </Box>
    )
}

export default UsuarioInicio
