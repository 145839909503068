import React , {useState,useEffect} from 'react'
import Modal from '../generales/ModalCerrar2';
import { makeStyles } from '@material-ui/core/styles';
import {Button,Card, Box, Typography} from '@material-ui/core'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display:'flex',
    flexDirection:'row'
  },

  formSelect: {
    width:'12em'
  },

  formDesc:{
      marginTop:'1.7em',
      width:'30em',
      height:'4em',
      display:'flex',
  },
  formImg:{
    marginTop:'.5em',
    width: 18+'rem',
    height:25+'rem',
    borderRadius: '.6rem'
  },
  cardDatos:{
    width:'50%',
    height:30.5+'rem',
},
}));



  export default function EditarImagen({modalAbierto,setModalAbierto,seleccionado,titulo,setActualizar,actualizar}){
    const classes = useStyles();
    let historial = useHistory();
    const [espera,setEspera]=useState(false)
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(true)
    const [botonesDeshab,setBotonesDeshab] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [imgPrimaria, setImgPrimaria] = useState();
    const [imgSecundaria,setImgSecundaria] = useState()
    const [portadaEnviar, setPortadaEnviar] = useState();
    const [contraEnviar,setContraEnviar] = useState()
    const [reiniciar,setReiniciar] = useState(false)
    
    useEffect(()=>{
      if(seleccionado.Portada){
        setImgPrimaria(seleccionado.Portada)
      }else{
        setImgPrimaria("T_LOGO_1.svg" )
      }
      if(seleccionado.Secundaria){
        setImgSecundaria(seleccionado.Secundaria)
      }else{
        setImgSecundaria("T_LOGO_1.svg" )
      }
    },[seleccionado])


  const onChangeImgPrimaria = (e) => {
    const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png)$/;      
    let regex2 = new RegExp("[$%@#&+(){}/:;!?,<>= ]", "g");      
    if ( e.target.files[0] && e.target.files[0].name.toLowerCase().match(regex) &&
      e.target.files[0].size < 10 * 1024 * 1024
    ) {
      let auxCode = uuidv4();
      //let auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)
      let arch = e.target.files[0];
      let auxNombre = auxCode + arch.name.slice(arch.name.lastIndexOf("."), arch.name.length);
      let auxNombre2 = auxNombre.replace(regex2, ""); //'probandoNuevo.pdf'        
      let myNewFile = new File([arch], auxNombre2, { type: arch.type });      
      setImgPrimaria(URL.createObjectURL(e.target.files[0]));
      setPortadaEnviar(myNewFile);
      setBtnDeshabilitado(false);
    } else {
      let mensaje = "Formato de archivo incorrecto, seleccione una imagen tipo jpg,jpeg o png";
      if (e.target.files[0]&& e.target.files[0].size >= 10 * 1024 * 1024) {
        mensaje = "El tamaño del archivo debe ser menor a 10mb.";
      }
      toast.warning(mensaje, {
        pauseOnFocusLoss: false,
        theme: "colored", toastId: `incorrecto-selPrim`,
      });
      setImgPrimaria("T_LOGO_1.svg");
      setPortadaEnviar();
    }
  };

  const onChangeImgSecundaria = (e) =>{
    const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png)$/
    if(e.target.files[0] && e.target.files[0].name.toLowerCase().match(regex) &&
      e.target.files[0].size < 10 * 1024 * 1024
    ){
      let auxCode=uuidv4()       
      //let auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)   
      let arch = e.target.files[0]
      let auxNombre=auxCode+arch.name.slice(arch.name.lastIndexOf("."),arch.name.length)
      let myNewFile = new File([arch], auxNombre, {type: arch.type});      
      setImgSecundaria(URL.createObjectURL(e.target.files[0]))
      setContraEnviar(myNewFile)
      setBtnDeshabilitado(false)
    }
    else
    { let mensaje = "Formato de archivo incorrecto, seleccione una imagen tipo jpg,jpeg o png";
      if (e.target.files[0]&& e.target.files[0].size >= 10 * 1024 * 1024) {
        mensaje = "El tamaño del archivo debe ser menor a 10mb.";
      }
      toast.warning(mensaje,{
        pauseOnFocusLoss: false,
        theme: "colored", toastId: `incorrecto-selSec`
      }); 
      setImgSecundaria("T_LOGO_1.svg" )
      setContraEnviar()
    }
  }

    async function subirImagen(auximg,auxtipo,auxtitulo){
        const source = axios.CancelToken.source(); 
        setBtnDeshabilitado(true)
        setBotonesDeshab(true)
        setEspera(true)
        let auxU=process.env.REACT_APP_LINK +`/publicacion-editar-img`;  
        let token = localStorage.getItem("token20");
        var bodyFormData = new FormData();
        bodyFormData.append('publicacion',seleccionado.Id)
        bodyFormData.append('tipo',auxtipo)
        bodyFormData.append('idDispositivo','wed4513s1c2s1cds1cd')
        bodyFormData.append('total',1)
        bodyFormData.append('multi-files',auximg)
        let config = {
            url:auxU ,
            method: 'POST',
            headers: {
                "access-token": token,
                'size':auximg.size,
                'tipo':'REVISTA-PORTADA',
                'revista':seleccionado.Id,
                "Content-Type": 'multipart/form-data',
              },
            data: bodyFormData,
            cancelToken: source.token,
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {          
          aux=  response.data;    
          if(aux.success){
          let mensaje = auxtitulo+" subida exitosamente";
            setTipoAdvertencia("success");
            setMensaje(mensaje);
            setAviso(true);   
            if(auxtipo===1){
              setPortadaEnviar()
            }else{
              setContraEnviar()
            }
            toast.success(mensaje,{
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `exito-env${auxtipo}`
            })
            setReiniciar(true)
          }
        }).catch(function (error) {          
          console.log(`Error: ${error}`);        
          if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
          {
            console.log('datos incorrectos' );  
          }else
          {
            if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
            {
              console.log('loguearse de nuevo');
              localStorage.clear();                      
              historial.push("/");                              
            } 
          }
        })
      setEspera(false)
    }


  function verificarImg (img){
    let validado=false;
    if(img && img.size){
        validado = true;
    }else{
     validado = false;
   }
   return validado;
}


    async function handleSubmission (){
      if(verificarImg(portadaEnviar)||verificarImg(contraEnviar)){
        if(portadaEnviar){
          await subirImagen(portadaEnviar,1,'Portada');
        }
        if(contraEnviar){
          await subirImagen(contraEnviar,2,'Contraportada')
        }
      }else{
        setMensaje("Seleccione una imagen para portada o contraportada");
        setAviso(true);
        setTipoAdvertencia("error");
      }
    }


  return(
  <Modal
    titulo={titulo} modalAbierto={modalAbierto}
    setModalAbierto={setModalAbierto} guardarDatos={handleSubmission}
    mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
    aviso={aviso} btnDeshabilitado={btnDeshabilitado}
    setAviso={setAviso} tamanio={'l'} cargando={espera}   
    actualizar={actualizar} setActualizar={setActualizar}
    reiniciar={reiniciar}      
  >
    <form className={classes.root} noValidate autoComplete="off"> 
      <Card className={classes.cardDatos} style={{marginRight:'3em'}}>
        <Typography id="primaria" style={{textAlign:'center'}}><b>Portada</b></Typography>
        <Box display="flex" flexDirection="column">
          <img className={classes.formImg} hidden={!imgPrimaria}  
            src={imgPrimaria ? imgPrimaria : ""} alt="Imagen Primaria"
          />
          <Box style={{marginTop:'1em',display:'flex',justifyContent:'center'}}>
            <input type="file" onChange={(e)=>onChangeImgPrimaria(e)} hidden id={"imgPrimaria"}
              accept="image/jpg,image/jpeg,image/png"disabled={botonesDeshab}  />
            <label htmlFor={"imgPrimaria"}>
              <Button variant="contained" component="span" color="primary" size='small' disabled={botonesDeshab}  >
                Cargar IMG
              </Button>
            </label>
          </Box>
        </Box>
      </Card>
      <Card className={classes.cardDatos}>
        <Typography id="secundaria" style={{textAlign:'center'}}><b>Contraportada</b></Typography>
        <Box display="flex" flexDirection="column">
          <img className={classes.formImg} hidden={!imgSecundaria}  
            src={imgSecundaria ? imgSecundaria : ""} alt="Imagen Secundaria"
          />
          <Box style={{marginTop:'1em',display:'flex',justifyContent:'center'}}>
            <input type="file" onChange={(e)=>onChangeImgSecundaria(e)} hidden id={"imgSecundaria"}
              accept="image/jpg,image/jpeg,image/png" disabled={botonesDeshab}  
            />
            <label htmlFor={"imgSecundaria"}>
              <Button variant="contained" component="span" color="primary" size='small' disabled={botonesDeshab}  >
                Cargar IMG
              </Button>
            </label>
          </Box>
        </Box>
      </Card>
    </form>
  </Modal>
  )
}
