import React , {useState,useEffect} from 'react'
import Modal from '../generales/Modal';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,FormControlLabel,FormLabel,Grid,Box,LinearProgress,
      InputLabel,MenuItem,Radio,RadioGroup,Select,TextField} from '@material-ui/core'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: {
      margin: theme.spacing(1),
    },
  },

  formAnotacion: {
    width:'15em'
  },
  formSelect:{
    marginTop:'.5em',
    marginBottom:'1.5em',
    width:'15em'
  },
  campos:{
    width: '200px',
  },

}));


  export default function EditarCategoria({modalAbierto,setModalAbierto,seleccionado,titulo,setActualizar,actualizar,listaFiltrada}){
    let historial = useHistory();   
    const classes = useStyles();
    const [listCatego, setListCatego] = useState([])
    const [espera,setEspera]=useState(false)
    const [esperaLlamada,setEsperaLlamada] = useState(false)
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [editActivo, setEditActivo] = useState("activo");
    const [subcategoria, setSubCategoria] = useState("no");
    const [aviso, setAviso] = useState(false);
    const [registro,setRegistro] = useState({nombre:"",status:1,idPadre:0})
    const [reiniciar,setReiniciar] = useState(false)
    const expPermitidaNombre = /^([A-Za-záéíóúÁÉÍÓÚñÑ]+)(?:[A-Za-z _.,ñÑáéíóúÁÉÍÓÚ]*)$/
    const {nombre,status,idPadre} = registro
    useEffect(()=>{
          setEsperaLlamada(true)
          setListCatego(listaFiltrada)
            if(seleccionado.Id!==seleccionado.IdPadre&&seleccionado.IdPadre!==0){
              setSubCategoria("si")
            }
             setRegistro({
               ...registro,
               nombre:seleccionado.Des,
               status:1,
               idPadre:seleccionado.IdPadre
             })
          setEsperaLlamada(false)
    },[seleccionado])

    const onChangeNombre = (e) =>{
      if(expPermitidaNombre.test(e.target.value)||e.target.value===' '||e.target.value===''){
        setRegistro({
          ...registro,
          nombre:e.target.value,
        });
      }
    }

    const onChangeIdPadre = (e) =>{
        setRegistro({
          ...registro,
          idPadre:e.target.value,
        });
    }

    const onChangeActivo = (event) => {
        event.preventDefault();
        setEditActivo(event.target.value);
        switch(event.target.value){
          case "activo":
            setRegistro({
              ...registro,
              status:1,
            });
          break;
          case "inactivo":
            setRegistro({
              ...registro,
              nombre:0,
            });
          break;
        }
      };
  
      const onChangeSubcategoria = (event) => {
        event.preventDefault();
        setSubCategoria(event.target.value);
      };
      
    function verificarNombre(nom){
      let validado = false;
      if(nom && nom!=" "){
        if(expPermitidaNombre.test(nom)){
          validado=true
        }else{
          validado = false;
          setMensaje("La descripción sólo puede incluir letras y espacios");
          setAviso(true);
          setTipoAdvertencia("error");
        }
      }else{
        validado = false;
        setMensaje("Ingrese descripción");
        setAviso(true);
        setTipoAdvertencia("error");
      }
      return validado;
    }

    function verificarIdPadre(id){
      let validado=false;
      if(id>0){
        validado=true
      }else{
        setMensaje("Seleccione una Opción Válida.");
        setAviso(true);
        setTipoAdvertencia("error");
      }
      return validado
    }
      async function registrarCategoria(){
        setEspera(true)
        setBtnDeshabilitado(true)
        const source = axios.CancelToken.source();    
        let auxU=process.env.REACT_APP_LINK +`/revista-categoria-editar`;  
        let token = localStorage.getItem("token20");
        let usuid = localStorage.getItem('UsuId') 
        let dataInfo = qs.stringify({                               
          'usuario':usuid,
          'categoria':seleccionado.Id,
          'status':status,
          'descr':nombre,
          'IdPadre':subcategoria==="si" ? idPadre : seleccionado.Id,
          'idDispositivo':'wed4513s1c2s1cds1cd'
          });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: {
                    "access-token": token,
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                data: dataInfo,
                cancelToken: source.token,
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux.respuesta===1){
                  let mensaje = "Se editó correctamente la categoría";
                  setTipoAdvertencia("success");
                  setMensaje(mensaje);
                  setAviso(true);   
                  setEspera(false)
                  setReiniciar(true)
                }else{
                  let mensaje = "Ocurrió un error, intente más tarde";
                  setTipoAdvertencia("warning");
                  setMensaje(mensaje);
                  setAviso(true);   
                  setEspera(false)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        historial.push("/");                              
                    } 
                }
            })
        }

    
    const handleSubmission = ()=>{
      if(verificarNombre(nombre)){
        if(subcategoria==="no"){
          registrarCategoria()
        }else{
          if(subcategoria==="si"&&verificarIdPadre(idPadre)){
            registrarCategoria()
          }
        }
      } 
    };


    function generarFormulario(){
      return(
        <form className={classes.root} noValidate autoComplete="off"> 
        <Grid container spacing={2}>
            <Grid item>
              <FormControl className={classes.formAnotacion}>
                <TextField required label="Descripción"
                              value={nombre} onChange={onChangeNombre}id={'nombre'}/> 
              </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item>
              <FormControl style={{marginTop:'1em'}}>
                <FormLabel>Subcategoría</FormLabel>
                <RadioGroup aria-label="subcategoria"
                  defaultValue={subcategoria}
                  onChange={onChangeSubcategoria}
                  name="Subcategoria"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControlLabel value="si" control={<Radio />} label="Si"  style={{color:'#F96550'}}/>
                    </Grid>
                    <Grid item xs={1}>
                      <FormControlLabel value="no" control={<Radio />} label="No"   style={{color:'#F96550'}}/>
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
        </Grid>
        { subcategoria==="si" && listCatego.length!==0?
            <FormControl className={classes.formSelect}>
                <InputLabel id={'idPadre'}>Categoría Principal</InputLabel>
                <Select
                label={'Estatus'} id={'idPadre'} name={'idPadre'} value={idPadre} onChange={onChangeIdPadre}
                >
                {listCatego.map((cat, index) => (
                    <MenuItem value={cat.Id} key={cat.Id}>
                    <em>{cat.Des}</em> 
                    </MenuItem>
                ))}
                </Select>
            </FormControl>:null}  
        <Grid container spacing={2}>
            <Grid item>
              <FormControl className={classes.campos}>
                <FormLabel>Visualizar</FormLabel>
                <RadioGroup aria-label="activo"
                  defaultValue={editActivo}
                  onChange={onChangeActivo}
                  name="Activo"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControlLabel value="activo" control={<Radio />} label="Si"  style={{color:'#F96550'}} />
                    </Grid>
                    <Grid item xs={1}>
                      <FormControlLabel value="inactivo" control={<Radio />} label="No"  style={{color:'#F96550'}} />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
        </Grid>
    </form>
      )
    }

    const validaCont = esperaLlamada ? <Box sx={{width:'100%'}}><LinearProgress style={{color:'#F96550'}}/></Box> : generarFormulario();
    return(
      <Modal
        titulo={titulo}
        modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto}
        guardarDatos={handleSubmission}
        mensaje={mensaje}
        tipoAdvertencia={tipoAdvertencia}
        aviso={aviso}
        btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso}
        tamanio={'l'}
        cargando={espera}   
        reiniciar={reiniciar}
        setActualizar={setActualizar}
        actualizar={actualizar}     
    >
      {validaCont}

    </Modal>
    )
  }