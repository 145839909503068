import React, {useState } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import {Typography, Icon} from '@material-ui/core';
import MenuContext from '../generales/MenuContext'
import InfoIcon from '@material-ui/icons/Info'
import CreateIcon from '@material-ui/icons/Create';
import EditarImagen from "./PublicacionEditImg";
import ImageIcon from '@material-ui/icons/Image'
import InfoPubli from "./InfoPubli";
import qs from 'qs'
export default function MenuPublicacion ({handleClose,valorCursor,seleccionado, rev,setActualizar,actualizar,abrirModalEditar}){
    //const [modalEditar,setModalEditar]= useState(false); 
    const [revista,setRevista] = useState({}); 
    const [modalInfo,setModalInfo]= useState(false);  
    const [modalImg,setModalImg] = useState(false)
    const [titulo,setTitulo] = useState('')
    React.useEffect(()=>{
      const auxRev = qs.parse(localStorage.getItem('revista')) 
      setRevista(auxRev)
    },[])
    function validarNulos(cadena){
      let respuesta=false
      if (cadena !== null && cadena !== undefined && cadena !== "" 
        && cadena !== ''&& cadena !== ' ') 
      {
        respuesta =true  
      }
      return respuesta
    }
    
    

    const modInfo = modalInfo ? (
                        <InfoPubli modalAbierto={modalInfo}  
                            setModalAbierto={setModalInfo} 
                            rev={rev}
                            titulo={titulo}
                            seleccionado={seleccionado}/>
                        ): null;
    
    const modImg = modalImg ? (
                        <EditarImagen modalAbierto={modalImg}  
                          setModalAbierto={setModalImg} 
                          seleccionado={seleccionado} titulo={titulo}
                          setActualizar={setActualizar} actualizar={actualizar}
                        />
                        ): null;


    function abrirModalInfo(){
      let auxTitulo=validarNulos(seleccionado.Nombre)?seleccionado.Nombre: revista.Nom
      setTitulo(auxTitulo)
      setModalInfo(!modalInfo);
    }

  

    function abrirModalImg(){
      //let auxTitulo=validarNulos(seleccionado.Nombre)?"Editar Portada/Contraportada de "+seleccionado.Nombre:"Editar Portada/Contraportada de "+revista.Nom
      let auxTitulo= validarNulos(seleccionado.Nombre)?seleccionado.Nombre:revista.Nom
      setTitulo(auxTitulo)      
      setModalImg(!modalImg)
    }

    

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  //const auxNombre=<Typography color="secondary" style={{fontWeight:600}}>{`${validarNulos(seleccionado.Nombre)?seleccionado.Nombre:revista.Nom}`}</Typography>

  return (             
  <MenuContext valorCursor={valorCursor} handleClose={handleClose}>
    <Typography style={{textAlign:'center',fontWeight:550, marginLeft:6, marginRight:6,color:'#F96550'}} 
      color="primary">
      {validarNulos(seleccionado.Nombre)?seleccionado.Nombre:revista.Nom} 
    </Typography>
    <br/>            
    <StyledMenuItem onClick={abrirModalInfo}>
      <Icon style={{marginRight:6}}>                    
        <InfoIcon style={{color:'#F96550'}}/>
      </Icon>
      <Typography variant="span">{`Información General`}</Typography>
      <span>&nbsp;</span>
      {/* auxNombre */}
    </StyledMenuItem>
    <StyledMenuItem onClick={abrirModalEditar}>
      <Icon style={{marginRight:6}}>                    
          <CreateIcon style={{color:'#F96550'}}/>
      </Icon>
      <Typography>{`Editar `}</Typography>
      <span>&nbsp;</span>
      {/* auxNombre */}
    </StyledMenuItem>
    <StyledMenuItem onClick={abrirModalImg}>
      <Icon style={{marginRight:6}}>                    
          <ImageIcon style={{color:'#F96550'}}/>
      </Icon>
      <Typography>{`Editar Portada/Contraportada `}</Typography>
      <span>&nbsp;</span>
      {/* auxNombre */}
    </StyledMenuItem>
    {modInfo}
    
    {modImg}
  </MenuContext>      
  )
}