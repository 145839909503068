import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { TextField,FormControl,//Select as MaterialSelect ,
        FormLabel,FormControlLabel,RadioGroup,Radio,
        //Box,InputLabel,Select,MenuItem,Tooltip,
        } from '@material-ui/core' 
//import DateFnsUtils from '@date-io/date-fns'; // choose your lib
//import esLocale from "date-fns/locale/es";
//import {DatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import Modal from '../generales/ModalCerrar';
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '20ch',
      }, 
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      Textnumero: {
        width: 5+"px",
      },
      formControlFull: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        width:5+"rem"
      },
      margin: {
        margin: theme.spacing(1),
      },
    },
  }));
const EditarUsuario = ({modalAbierto,setModalAbierto ,titulo,btnCargar,listTipoUsu,usrSelecc }) => {
    let history = useHistory();
    const classes = useStyles();
  
    const[nvoCliente,setNvoCliente]=React.useState({Nombre:'',appat:'',apmat:'',
                                                FNacimien:'Mon Oct 25 1900 16:42:31 GMT-0600 (hora de verano central)',
                                                Email:'' ,Pass:'',Tel:'',TipoUsu:'2',usuNick:'',idUsu:'',stdUsu:'',Puesto:0
                                            });                                                
    
    const [espera,setEspera]=React.useState(false);
    const [mensaje, setMensaje] = React.useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= React.useState(false)
    const [aviso, setAviso] = React.useState(false);
    //const [auxLlave, setAuxLlave] = React.useState([]);
    const [listPuesto, setListPuesto] = React.useState([]); 
    const{  Nombre,appat,apmat,FNacimien,Email,Pass,Calle,Tel,TipoUsu,usuNick,idUsu,stdUsu,Puesto}=nvoCliente
    let colorCheckR='#F96550'
    const source = axios.CancelToken.source();  
    React.useEffect(()=>{
        console.log(usrSelecc);
        //let arrNombre = usrSelecc.Nombre.split(" ");
        let auxNom= usrSelecc.Nombre//arrNombre.length>0 ? arrNombre[0]:""
        let auxApPat = usrSelecc.Paterno//arrNombre[1] !== undefined ? arrNombre[1]:""
        let auxApMat = usrSelecc.Materno//arrNombre[2] !== undefined ? arrNombre[2]:""
        setNvoCliente({
            ...nvoCliente,
            Nombre:auxNom ,appat:auxApPat ,apmat:auxApMat ,Email:usrSelecc.Email ,Pass:usrSelecc.Pass 
            ,TipoUsu:usrSelecc.Tipo.toString() ,usuNick:usrSelecc.Usuario
            ,idUsu:usrSelecc.Id ,stdUsu:usrSelecc.Status
             ,Tel:usrSelecc.Tel 
        })
        
        async function llenaListPuesto( )  {       
            setEspera(true);
            let auxU=process.env.REACT_APP_LINK +`/usuario-puesto-list`;                               
            let token =localStorage.getItem('token20') ;               
            let usuId =localStorage.getItem('UsuId') ;                                      
            let dataInfo = qs.stringify({                                                                   
                'usuario':usuId,                
                'idDispositivo':'451224452121'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {              
                aux=  response.data;                                          
                //console.log(aux)
                if (aux.length!==0) 
                {                
                    setListPuesto(aux);                                              
                }                                            
            })
            .catch(function (error) {                  
                console.log(`Error: ${error}`);              
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                    } 
                }
            });             
            setEspera(false);         
        };  
        //llenaListPuesto()
    },[])
               
    
    const onChange = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[.%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value.toUpperCase()  
            })            
        }		
	};         

    const onChangeNum = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');  
        //let expNopermitida = new RegExp('[-%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');
        //let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value.toUpperCase()  
            })            
        }		
	}; 

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');  
        //let expNopermitida = new RegExp('[-%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');
        //let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
    
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoCliente({
                ...nvoCliente, //copiamos el nvocliente
                [e.target.name] : e.target.value.toUpperCase()  
            })            
        }		    

	}; 
 
    const onChangeSelect = e =>
	{   e.preventDefault();
        setNvoCliente({
            ...nvoCliente, 
            [e.target.name] : e.target.value  
        })                       
	};      
        
    const guardaCheckRad = e =>
    {  //console.log(e);
        setNvoCliente({
            ...nvoCliente,
            [e.target.name] : e.target.value  
        })  
    };     

    const guardaCheckRad2 = e =>
    {  
        setNvoCliente({
            ...nvoCliente,
            Nacionalidad : e.target.value  
        })  

    };   
    
    async function llamadaEditCliente( )  {       
        setEspera(true);
        let auxU=process.env.REACT_APP_LINK +`/usuario-editar`;                               
        let token =localStorage.getItem('token20') ;               
       // let usuId =localStorage.getItem('UsuId') ;                                      
        let dataInfo = qs.stringify({     
            'llave':idUsu,                                                              
            'tipo':parseInt(TipoUsu),
            'estatus':stdUsu,
            'nombre':Nombre,
            'paterno':appat,
            'materno':apmat,
            'usuario':usuNick,
            'pass':Pass,
            'email':Email,
            'puesto':parseInt(TipoUsu),
            'tel':Tel,             
            'idDispositivo':'451224452121'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {              
          aux=  response.data;                                          
            console.log(aux)
          if (aux.length!==0) 
          {                
            //setNvoRegistro(aux[0]);  
                        
            setBtnDeshabilitado(true)                                             
            let mensaje="Se edito correctamente el Usuario."
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true)     
          }                                            
        })
        .catch(function (error) {                  
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
        });             
        setEspera(false);         
    };

    const onChangeCorreo = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/.;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) && !expMenos.test(e.target.value) &&
            !expMas.test(e.target.value) ) || e.target.value===' '|| e.target.value==='') 
        { 
            setNvoCliente({
                    ...nvoCliente, //copiamos el nvocliente
                    [e.target.name] : e.target.value  
                })
            if (aviso) 
            {   setMensaje('');
                setAviso(false);
            }
        }		
	};    

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
            console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }
    

    const verificaNulosSelect = (campo,descripcion)=>{
        let respuesta =false;              
        if(campo === '' || campo === null ||campo == 0 )
        {   respuesta=true;                             
            setMensaje('Debe seleccionar campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }console.log(campo+descripcion);  
        return(respuesta);
    }

    const verificaNombre=(nombre,appat,apmat)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('[A-Z]');
        let expNumero =  new RegExp('[0-9]');
        if (!verificaNulos(nombre,'Nombre') && !verificaNulos(appat,'Apellido Paterno') && !verificaNulos(apmat,'Apellido Materno')  ) 
        {        
            if( expCadena.test(nombre) && !expNumero.test(nombre) )
            {
                if(  expCadena.test(appat) && !expNumero.test(appat) )
                {
                    if ( expCadena.test(apmat) && !expNumero.test(apmat)  ) 
                    {  
                        respuesta=true;                     
                    }else
                    {   setMensaje('Debe escribir un Apellido Materno  valido ');
                        setTipoAdvertencia("warning")  
                        setAviso(true);      
                    }
                }
                else
                {   setMensaje('Debe escribir un Apellido Paterno  valido ');
                    setTipoAdvertencia("warning")  
                    setAviso(true);
                }
            }
            else
            {   setMensaje('Debe escribir un Nombre  valido ' );
                setTipoAdvertencia("warning")  
                setAviso(true);
            }    
        }    
        return(respuesta);
    }
    
    /*const verificaCorreo=(correo)=>
    {   let respuesta =false;        
        let expCadena = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');        
        if( correo.trim() === '' || correo.trim() === null  )
        {  // respuesta=true;      
            setMensaje('Campo Email vacio, debe llenar el campo ' );
            setTipoAdvertencia("warning")  
            setAviso(true);                     
        }
        else{
            if ( expCadena.test(correo)  ) 
            {   respuesta=true;
            }
            else
            {   setMensaje('Debe escribir un Correo valido ' );
                setTipoAdvertencia("warning")  
                setAviso(true);                           
            }            
        }
        return(respuesta);
    } */  
  
    /*const veriNuloNum = (campo,descripcion)=>{
        let respuesta =false; 
        console.log(descripcion+ ' '+campo); 
        console.log(campo);                                 
        if((campo==='0'||campo===0 )|| campo === null ||campo === ""|| campo === " ")
        {   respuesta=true;                    
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    } */

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }

    function valContra() {
        let respuesta=false
        if ( !verificaNulos(Pass,'Contraseña') ) 
        {            
            if (Pass.length>=4 ) 
            {
                respuesta=true    
            }else
            {
                //mandaAlerta('Minimo 4 caracteres en la contraseña',"warning") 
                mandaAlerta('Campo Contraseña incompleto, debe escribir una Contraseña valida',"warning") 
            }
        }        
        return respuesta;
    }

    function valDireccion() 
    {   let respuesta=false
        if ( !verificaNulos(Calle,'Calle') ) 
        {   if (Calle.length>=4 ) 
            {                
                respuesta=true                    
            }else
            {   mandaAlerta('Campo Calle incompleto, debe escribir una Calle valida',"warning") 
            }
        }        
        return respuesta;
    }

    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }


    function verificaUsu() 
    {   let respuesta=false
        if ( !verificaNulos(Email,'Email') ) 
        {   if (Email.length>=8 ) 
            {                
                respuesta=true                    
            }else
            {   mandaAlerta('Campo Email incompleto, minimo 8 caracteres , debe escribir un Email valido',"warning") 
            }
        }        
        return respuesta;
    }
  
  function guardarDatos(e)
    {   e.preventDefault()
        console.log("guardando  modal");
           // se cambio el verificar email por usuario !verificaNulos(Email,'Usuario') 
        if (!verificaNulos(TipoUsu,'Tipo') && validaVariable(Nombre,'Nombre',4) && validaVariable(appat,'Apellido Paterno',4) 
            && validaVariable(apmat,'Apellido Materno',4) && validaVariable(usuNick,'Usuario',4)
            && valContra() )
        {
            if (verificaUsu() ) 
            {
                setMensaje('');                
                setAviso(false);                 
                llamadaEditCliente()                 
            }          
        }                                  
    }   
 
 
    const DatosGenerales=(
        <div>
            {listTipoUsu.length > 0 ?
                <FormControl  style={{ paddingRight:1.5+'rem'}} 
                    size="small" component="fieldset"
                >              
                <FormLabel component="legend" style={{color:colorCheckR,fontWeight:500}}>
                 Tipo
                </FormLabel>      
                <RadioGroup   row   aria-label="position" defaultValue="top"   
                    name="TipoUsu" value={TipoUsu} onChange={guardaCheckRad}
                >                                
                    <span> 
                    {listTipoUsu.map((auxTipo)=>
                        
                        {if (auxTipo.Id>0) {
                        return  <FormControlLabel value={auxTipo.Id.toString()} style={{color:colorCheckR}} 
                                control={<Radio  style={{color:colorCheckR}} />} label={auxTipo.Tipo} 
                            />     
                        }}                                
                    )}                                           
                    </span>                        
                </RadioGroup>                     
                </FormControl>
            :null  }
            <br/>
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:9+'rem',marginRight:.5+'rem'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" // defaultValue="Nombre"        
                color="secondary"        
            />
            <TextField  id="appat-input" onChange={onChange} style={{marginRight:.5+'rem',paddingBottom:.5+'rem',width:8+'rem'}}
                label="Apellido Paterno" value={appat} name='appat'size="small"  //required
                color="secondary"  
            />
            <TextField  id="apmat-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:8+'rem'}}
                label="Apellido Materno" value={apmat} name='apmat'size="small" //required
                color="secondary"  
            />
            <br/>
            <TextField  id="Usu-input" onChange={onChangeCorreo} style={{width:12.5+'rem',marginRight:1+'rem',paddingBottom:.5+'rem',}}
                label="Usuario"  value={usuNick} name='usuNick' size="small"//defaultValue="Usuario"
                autoComplete='off'  color="secondary"  
            />      
            <TextField
                required name="Pass" label="Contraseña" type="password" id="Pass"
                value={Pass} onChange={onChange} size="small" style={{width:12.5+'rem'}}     
                autoComplete='off'  color="secondary"  
            />        
            <br/>
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{marginRight:2+'rem',width:7.5+'rem'}}
                label="Telefono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
                inputProps= {{ maxlength: 10 }} autoComplete='off'  color="secondary"                 
            />
            {/*   */}
            <TextField  id="Email-input" onChange={onChangeCorreo} style={{paddingBottom:1+'rem',width:16.5+'rem'}}
                label="Email"  value={Email} name='Email' size="small"//defaultValue="Usuario"
                autoComplete='off'  color="secondary"  
            />             
        </div> 
    )       
 
    return (
        <>           
         <Modal
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} btnDeshabilitado={btnDeshabilitado}
            setAviso={setAviso} tamanio={'md'} cargando={espera}        
        >
            {DatosGenerales}
        </Modal> 
            
        </>
    )
}

export default EditarUsuario
