import React,{useState, useEffect} from 'react'
import {Box,LinearProgress, IconButton, Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import TablaCategoria from './TablaCategoria'
import NuevaCategoria from './NuevaCategoria';
import axios from 'axios';
import qs from 'qs';
import CardImgPortada from './CardImgPortada';


const useStyles = makeStyles((theme) => ({
    botonCrear:{
        marginTop:'.2em',
        width:'20',
        height:'20'
    },
    botonBuscar:{
        marginTop:'.2em',
        width:'20',
        height:'20'
    },
    formSelect:{
        width:'12em',
    }
  }));


export default function CategoriaInicio(){
    const classes = useStyles();
    const [listCategoria,setListCategoria] = useState([])
    const [listaFiltrada,setListaFiltrada] = useState([])
    const [listaFiltradaMod,setListaFiltradaMod] = useState([])
    const [espera, setEspera] = useState(true);
    const [modalNuevaCat, setModalNuevaCat] = useState(false);
    const [actualizar,setActualizar]=React.useState(false);
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    const [seleccionado,setSeleccionado] = useState([])
    let historial = useHistory();     
    
    useEffect(()=>
    { 
        const source = axios.CancelToken.source();    
        async function getCategorias(){
            setEspera(true)
            let auxU=process.env.REACT_APP_LINK +`/revista-categoria-list`;  
            let usuid = localStorage.getItem('UsuId') 
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({                               
                'usuario':usuid,
                'idDispositivo':'wed4513s1c2s1cds1cd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: {
                    "access-token": token,
                    "Content-Type": "application/x-www-form-urlencoded",
                    },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1){
                    let auxSinID = aux.filter((catego)=>catego.Id!==0);
                    setListCategoria(auxSinID)
                   let auxFiltro= aux.filter((catego)=>((catego.Id===catego.IdPadre||catego.IdPadre===0)&&catego.Id===0))
                   if(auxFiltro.length!==0){
                       setListaFiltrada(auxFiltro)
                   }else{
                        setListaFiltrada([])
                   }
                    let arrAux=aux
                    arrAux.splice(0,0,{Id:0,Des:"Seleccione una Opción"});
                    setListaFiltradaMod(arrAux)
                }else{
                    setListaFiltrada([])
                    setListCategoria([])
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
            })
            
        setEspera(false)
        }

      const autenticado = authUser();
      if(!autenticado){
        localStorage.clear();
        historial.push('/');
        guardarUsuario([]);    
      }else{
        const usu= localStorage.getItem('UsuarioNom');
        const nvl= localStorage.getItem('Tipo');           
        if (usuario.length===0) 
        {
            guardarUsuario({Nombre:usu,nivel:nvl});                                         
            
        }        
       // document.title = 'Revistas'                                 
        guardarGenerales({...generales,Titulo:'Categorías'})   
        getCategorias();
    }},[actualizar])
    
    function abrirModalNuevaCat(){  
        setModalNuevaCat(!modalNuevaCat);    
    }

    const modNvaCat= modalNuevaCat ? <NuevaCategoria modalAbierto={modalNuevaCat} setModalAbierto={setModalNuevaCat}
                                    actualizar={actualizar} setActualizar={setActualizar}/> :null;
    return(
    <div style={{marginLeft:'2em',marginTop:'1em'}}>
    {espera?  <Box style={{width:'600px'}}><LinearProgress/> </Box> :
        <div>
            <div style={{display:'flex', flexDirection:'row'}}>
                <Tooltip title={`Actualizar tabla`}>
                    <IconButton    size="small" onClick={() => setActualizar(!actualizar)} 
                        style={{marginRight:.2+'rem'}} component="span" aria-label="refresh"
                    >                
                        <RefreshIcon  color="secondary" style={{ marginBottom:7+'px'}}/>
                    </IconButton>
                </Tooltip> 
                <Tooltip title="Agregar Revista">
                    <IconButton   size="small" onClick={abrirModalNuevaCat}
                        component="span" style={{marginRight:.6+'rem'}}  aria-label="add circle"
                    >
                        <AddCircleOutlineIcon style={{marginBottom:7+'px'}} color="secondary"  />  
                    </IconButton>
                </Tooltip>
            </div>
            <Box display={'flex'} flexDirection="row" >                
                <Box display={'flex'} > 
                    <TablaCategoria listaModal={listaFiltradaMod}
                            listaFiltrada={listaFiltrada} listaCompleta={listCategoria} 
                            actualizar={actualizar} setActualizar={setActualizar}
                        seleccionado={seleccionado} setSeleccionado={setSeleccionado} 
                    />
                </Box>    
                <Box display={'flex'} >
                    { seleccionado.length !== 0 ? <CardImgPortada  seleccionado={seleccionado}/>: null }
               
                </Box>    
            </Box>            
            {modNvaCat}
        </div>
    }
    </div>
    )
}