import React, {  useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, LinearProgress, TableBody, Table,TableContainer,Collapse,
    TableHead,TableCell,TableRow, IconButton, Tooltip} from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import EditarCategoria from "./EditarCategoria";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
const useStyles = makeStyles((theme) => ({
    root: {
      //alignContent: 'center',
     // marginTop : '.5em',
      //paddingLeft:1.5+'rem'
    },
    container: {
        width:300,
        minHeight: 280,
        //minWidth:'100%',
      },
      container2: {
        maxHeight: 550,
        //maxHeight: window.innerHeight-170,
        //minHeight: 280,
      },
      tableRow: {
        "&$selected, &$selected:hover": {
          backgroundColor: "#DF0006"
        }
      },
      tableCell: {
        "$selected &": {
          color: "white"
        }
      },
      hover: {},
      selected: {}
}));

export default function TablaCategoria({listaFiltrada,listaCompleta,listaModal,seleccionado,setSeleccionado,setActualizar,actualizar}){
    const alturaTabla = window.innerHeight<937 ? window.innerHeight-200 : window.innerHeight-340
    const alturaTabla2 = window.innerHeight<937 ? window.innerHeight-700 : window.innerHeight-740
    const classes = useStyles();
    const [espera,setEspera] = useState(false)
    const [listaCat,setListaCat] = useState(listaCompleta)
    const [idSelec,setIdSelec] = useState(-1);
    const [idSub,setIdSub] = useState(-1)
    const [seleccionadoSub,setSeleccionadoSub] = useState([])
    //const [seleccionado,setSeleccionado] = useState([])
    const [listSubcatego,setListSubcatego] = useState([])
    const [modalEditar,setModalEditar] = useState(false)
    const [open, setOpen] = React.useState(false);


    useEffect(()=>{
        setEspera(true)
        setListaCat(listaCompleta)
        setEspera(false)
    },[listaCompleta])


    const filaSelec=(auxCat)=>{
        setIdSelec(auxCat.Id)
        setSeleccionado(auxCat)
        setIdSub(-1)
        setSeleccionadoSub([])
      }

      const filaSelecSub=(auxSub)=>{
        setIdSub(auxSub.Id)
        //console.log(auxCat);
        setSeleccionadoSub(auxSub)
      }

    function abrirModalEditar(){
        setModalEditar(!modalEditar)
    }

    function verDetalles(auxCat) {
        setIdSelec(auxCat.Id)
        setSeleccionado(auxCat)
        setIdSub(-1)
        setSeleccionadoSub([])
        if(listaCompleta.length!==0){
            let auxFiltro= listaCompleta.filter((catego)=>catego.IdPadre===auxCat.Id)
            if(auxFiltro.length!==0){
                var indice = auxFiltro.findIndex(elem => elem.Id === elem.IdPadre)
                if(indice>-1){
                    let arregloSinPadre= auxFiltro;
                    arregloSinPadre.splice(indice,1)
                    setListSubcatego(arregloSinPadre)
                }else{
                    setListSubcatego(auxFiltro)
                }
            }else{
                setListSubcatego([])
            }
        }
        if (auxCat.Id ===idSelec) 
        {
          setOpen(!open)
        }
        else
        {
          setOpen(true)
        }
      }
    function sinDatos(){
        return(
        <TableContainer className={classes.container2} style={{height: 150}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow >                                                            
                    <TableCell style={{ color:'#DF0006',fontWeight:600,width:'20px'}} align="center" padding="none">
                        0  
                    </TableCell>                      
                    <TableCell style={{ color:'#F96550',fontWeight:600}} align="left" padding="none">
                        Descripción 
                    </TableCell>  
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell padding='none' align="center">Sin</TableCell>              
                    <TableCell padding='none' align="center">Resultados</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer>
        )
    }


    function generarBotones(open,filaCate){
        let boton=<div></div>

        if(idSelec===filaCate){
            if(open){
                boton= <KeyboardArrowUpIcon style={{color:'white'}}/>
            }else{
                boton=<KeyboardArrowDownIcon style={{color:'white'}}/>
            }
        }else{
            boton=<KeyboardArrowDownIcon style={{color:'#F96550'}}/>
        }

        return boton

    }
/*
    function tablaSubcategorias(){
        return(
        //<TableContainer style={{minHeight:30,height:alturaTabla2}}>
        <Table size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow> 
                            <TableCell align="center" padding={'none'} style={{ color:'#F96550',fontWeight:600,width:'10px'}} 
                                align="center" padding="none">
                                <b>{listSubcatego.length}</b>
                            </TableCell>  
                            <TableCell style={{ color:'#F96550',fontWeight:600}}padding={'none'} >
                                Subcategorías
                            </TableCell>                    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listSubcatego.map((subcatego,index)=>{
                            return(
                                <TableRow onClick={ () => { filaSelecSub(subcatego); } }
                                className={classes.tableRow} selected={ idSub === subcatego.Id}
                                classes={{ hover: classes.hover, selected: classes.selected }}  
                                >       
                                <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
                                    <Tooltip title={`Editar Categoría`}>
                                        <IconButton   aria-label="add circle" size="small" 
                                            component="span" onClick={abrirModalEditar}
                                        >
                                        {idSub === subcatego.Id ? 
                                            <CreateIcon style={{color:'white',width:18+'px'}} />
                                            :<CreateIcon style={{width:18+'px',color:'#F96550'}}/>
                                        }
                                        </IconButton>                     
                                    </Tooltip>                                                                                                                                                       
                                </TableCell>               
                                <TableCell align="left" padding={'none'} style={{ paddingLeft:.5+'rem'}}>
                                    {subcatego.Des}
                                </TableCell>
                            </TableRow>
                            )
                        })}
                    </TableBody>
            </Table>
         //</TableContainer>
        )
    }
    */

    function tablaSubcategorias(){
        return(
        //<TableContainer style={{minHeight:30,height:alturaTabla2}}>
        <Table size="small" aria-label="sticky table">
                    <TableHead>
                        <TableRow color="primary"> 
                            <TableCell>
                            </TableCell>    
                            <TableCell style={{ color:'#F96550',fontWeight:600}}padding={'none'} >
                                Subcategorías
                            </TableCell>                    
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {listSubcatego.map((subcatego,index)=>{
                            return(
                                <TableRow onClick={ () => { filaSelecSub(subcatego); } } ley={subcatego.Id}
                                className={classes.tableRow} selected={ idSub === subcatego.Id}
                                classes={{ hover: classes.hover, selected: classes.selected }}  
                                >       
                                <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
                                    <Tooltip title={`Editar Subcategoría`}>
                                        <IconButton   aria-label="add circle" size="small" 
                                            component="span" onClick={abrirModalEditar}
                                        >
                                        {idSub === subcatego.Id ? 
                                            <CreateIcon style={{color:'white',width:18+'px'}} />
                                            :<CreateIcon style={{width:18+'px',color:'#F96550'}}/>
                                        }
                                        </IconButton>                     
                                    </Tooltip>                                                                                                                                                       
                                </TableCell>                   
                                <TableCell align="left" padding={'none'} style={{ paddingLeft:.5+'rem'}}>
                                    {subcatego.Des}
                                </TableCell>
                            </TableRow>
                            )
                        })}
                    </TableBody>
            </Table>
         //</TableContainer>
        )
    }

    function subcategoriaSindatos(){
        return(
        //<TableContainer style={{minHeight:30,height:alturaTabla2}}>
        <Table size="small" aria-label="sindatos">
            <TableHead>
                <TableRow color="primary">
                    <TableCell >
                    </TableCell>   
                    <TableCell style={{ color:'#F96550',fontWeight:600}}padding={'none'} >
                        Subcategorías
                    </TableCell>                    
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow key={-1}>     
                    <TableCell align="left" padding={'none'} style={{ paddingLeft:.5+'rem'}}>
                        Sin
                    </TableCell>               
                    <TableCell align="left" padding={'none'} style={{ paddingLeft:.5+'rem'}}>
                        Resultados
                    </TableCell>
                </TableRow>
            </TableBody>
    </Table>
    //</TableContainer>
    )
    }
    function generarTabla(){
        return( 
        <TableContainer className={classes.container} style={{height: alturaTabla}}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow >   
                    <TableCell style={{ color:'#F96550',fontWeight:600,width:'20px'}} 
                        align="center" padding="none"
                    >
                        <b>{listaCat.length}</b>
                    </TableCell>  
                    <TableCell align="center" padding={'none'}>
                    </TableCell>                                    
                    <TableCell style={{ color:'#F96550',fontWeight:600}} align="left" padding="none">
                        Descripción 
                    </TableCell>   
                </TableRow>
            </TableHead>
            <TableBody>
            {listaCat.map((tag,index)=>{
                let conSubcategorias =  listaCompleta.filter((catego)=>catego.IdPadre===tag.Id && catego.IdPadre!==catego.Id).length>0
                return(
                <React.Fragment>
                    <TableRow onClick={ () => { filaSelec(tag); } }
                        className={classes.tableRow} selected={ idSelec === tag.Id}
                        classes={{ hover: classes.hover, selected: classes.selected }}  
                    >
                        <TableCell align="center" padding={'none'} style={{ width:1+'rem'}}>
                        {  conSubcategorias ? 
                        <IconButton color="primary"  aria-label="expand row" size="small" onClick={() => verDetalles(tag)}>
                            {generarBotones(open,tag.Id)}          
                        </IconButton>
                        :null}
                        </TableCell>  
                        <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
                            <Tooltip title={`Editar Categoría`}>
                                <IconButton   aria-label="add circle" size="small" 
                                    component="span" onClick={abrirModalEditar}
                                >
                                {idSelec === tag.Id ? 
                                    <CreateIcon style={{color:'white',width:18+'px'}} />
                                    :<CreateIcon style={{width:18+'px',color:'#F96550'}}/>
                                }
                                </IconButton>                     
                            </Tooltip>                                                                                                                                                       
                        </TableCell>        
                        <TableCell className={classes.tableCell} padding='none' align="left" 
                            style={{width:'200px',paddingLeft:'.4em'}}>
                            {tag.Des}
                        </TableCell>
                    </TableRow>
                    {conSubcategorias?
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
                            <Collapse in={open && idSelec=== tag.Id} timeout="auto" unmountOnExit>
                                <Box margin={1}>                
                                {listSubcatego.length!==0?
                                tablaSubcategorias()
                                :subcategoriaSindatos()}
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                    :null}
                </React.Fragment>
                )
            })}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }
    const modEditar = modalEditar ? <EditarCategoria modalAbierto={modalEditar} 
                                        titulo={idSub>0 ? ('Editar ' +seleccionadoSub.Des) : ('Editar ' +seleccionado.Des)}
                                        setModalAbierto={setModalEditar} 
                                        seleccionado={idSub>0 ? seleccionadoSub : seleccionado}
                                        listaFiltrada={listaModal}
                                        //seleccionado={seleccionado}
                                         actualizar={actualizar} setActualizar={setActualizar}/> : null;
    const contenido = listaCat.length !== 0 ? generarTabla() : sinDatos();
    const validaCont = espera ? <Box sx={{width:'100%'}}> <LinearProgress style={{color:'#F96550'}} /> </Box> : contenido;
    return( 
        <div className={classes.root}>
            {validaCont}
            {modEditar}
        </div>
    )
}