import React, {useState ,useEffect} from "react";
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import {Typography, Icon} from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview'
import ImageIcon from '@material-ui/icons/Image'
import MenuContext from '../generales/MenuContext'
import EditarImagenRevista from "./RevistaEditImg";
import { useHistory } from "react-router-dom";
import qs from 'qs';

export default function MenuRevista ({handleClose,valorCursor,seleccionado,setActualizar,actualizar}){
  const [revista,setRevista] = useState({}); 
  const [modalImg,setModalImg] = useState(false)
  const [titulo,setTitulo] = useState('')
  let historial = useHistory(); 

  useEffect(()=>{
    setRevista(seleccionado)
  },[seleccionado])

  function validarNulos(cadena){
    let respuesta=false
    if (cadena !== null && cadena !== undefined && cadena !== "" 
      && cadena !== ''&& cadena !== ' ') 
    {
      respuesta =true  
    }
    return respuesta
  }

  const modImg = modalImg ? (
    <EditarImagenRevista modalAbierto={modalImg}  
        setModalAbierto={setModalImg} 
        seleccionado={seleccionado}
        setActualizar={setActualizar}
        actualizar={actualizar}
        titulo={titulo}/>
    ): null;

    function abrirModalImg(){
      let auxTitulo=validarNulos(seleccionado.Nom)?"Editar Imagen de "+seleccionado.Nom:"Editar Imagen"
      setTitulo(auxTitulo)      
      setModalImg(!modalImg)
    }

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  return (        
  <MenuContext valorCursor={valorCursor} handleClose={handleClose}>      
    <Typography style={{textAlign:'center',fontWeight:550, marginLeft:15, marginRight:15,color:'#F96550'}} >
      {validarNulos(seleccionado.Nom)?seleccionado.Nom:"Revista"} 
    </Typography>
    <br/>            
    <StyledMenuItem onClick={abrirModalImg}>
      <Icon style={{marginRight:6}}>                    
        <ImageIcon style={{color:'#F96550'}}/>
      </Icon>
      <Typography>{`Editar Imagen `}</Typography>
      <span>&nbsp;</span>
      {/* auxNombre */}
    </StyledMenuItem>
    {modImg}
  </MenuContext>    
  )
}