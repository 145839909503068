import React from 'react';
import {Box, Card, CardMedia} from '@material-ui/core';
const CardImgPortada = ({seleccionado}) => {
    const [selectImg, setSelectImg]=React.useState([])
    React.useEffect(()=>{
        //console.log(seleccionado);
       setSelectImg(seleccionado)
    },[seleccionado])
    return( 
    <>
    <Box pl={2}>
        <Card style={{width:15+'rem',height:12.5+'rem'}}>
        {selectImg.Img!=="" ?<CardMedia //style={{width:"10rem"}}
                component="img" alt="Categoria Magazine"
                height="200" title="Categoria Magazine"
                image={selectImg.Dir+selectImg.Img} 
            />:
            <CardMedia 
                style={{height:"12rem",width:"13rem",paddingLeft:1.8+'rem',paddingTop:.5+'rem',paddingBottom:.5+'rem'}}
                component="img" alt="Categoria Magazine"
                title="Categoria Magazine"
                image={"/T_LOGO_1.svg"} 
            />
        }
        </Card>
    </Box>
        
    </>
    );
};

export default CardImgPortada;
