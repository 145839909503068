import React, { useState, useEffect } from "react";
import Modal from "../generales/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, Box, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },

  formSelect: {
    width: "12em",
  },

  formDesc: {
    marginTop: "1.7em",
    width: "30em",
    height: "4em",
    display: "flex",
  },
  formImg: {
    marginTop: ".5em",
    width: "200px",
    height: "200px",
  },
  cardDatos: {
    width: "100%",
    height: 290,
  },
}));

export default function EditarImagenRevista({
  modalAbierto,
  setModalAbierto,
  seleccionado,
  titulo,
  setActualizar,
  actualizar,
}) {
  const classes = useStyles();
  let historial = useHistory();
  const [espera, setEspera] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
  const [btnDeshabilitado, setBtnDeshabilitado] = useState(false);
  const [botonesDeshab, setBotonesDeshab] = useState(false);
  const [reiniciar, setReiniciar] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [imgRevista, setImgRevista] = useState();
  const [imgEnviar, setImgEnviar] = useState();

  useEffect(() => {
    if (seleccionado.Dir && seleccionado.Img) {
      setImgRevista(seleccionado.Dir + seleccionado.Img);
    } else {
      setImgRevista("T_LOGO_1.svg");
    }
  }, [seleccionado]);

  const onChangeimgRevista = (e) => {
    const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.jpg|.jpeg|.png)$/;
    if (
      e.target.files[0] &&
      e.target.files[0].name.toLowerCase().match(regex)
    ) {
      let auxCode = uuidv4();
      //let auxRecorte=auxCode.slice(auxCode.lastIndexOf("-")+1,auxCode.length)
      let arch = e.target.files[0];
      let auxNombre =
        auxCode + arch.name.slice(arch.name.lastIndexOf("."), arch.name.length);
      let myNewFile = new File([arch], auxNombre, { type: arch.type });
      // console.log(myNewFile);
      setImgRevista(URL.createObjectURL(e.target.files[0]));
      setImgEnviar(myNewFile);
    } else {
      toast.warning(
        "Formato de archivo incorrecto, seleccione una imagen tipo jpg,jpeg o png",
        {
          pauseOnFocusLoss: false,
          theme: "colored",
          toastId: `incorrecto-selRev`,
        }
      );
      setImgRevista("T_LOGO_1.svg");
      setImgEnviar();
    }
  };

  async function subirImagen() {
    const source = axios.CancelToken.source();
    setBtnDeshabilitado(true);
    setBotonesDeshab(true);
    setEspera(true);
    let auxU = process.env.REACT_APP_LINK + `/revista-editar-img`;
    let token = localStorage.getItem("token20");
    var bodyFormData = new FormData();
    bodyFormData.append("revista", seleccionado.Id);
    bodyFormData.append("idDispositivo", "wed4513s1c2s1cds1cd");
    bodyFormData.append("multi-files", imgEnviar);
    let config = {
      url: auxU,
      method: "POST",
      headers: {
        "access-token": token,
        size: imgEnviar.size,
        tipo: "PUBLIC",
        "Content-Type": "multipart/form-data",
      },
      data: bodyFormData,
      cancelToken: source.token,
    };
    let aux = {};
    await axios(config)
      .then(function (response) {
        aux = response.data;
        if (aux.success) {
          let mensaje = "Imagen subida exitosamente";
          setTipoAdvertencia("success");
          setMensaje(mensaje);
          setAviso(true);
          setImgEnviar();
          toast.success(mensaje, {
            pauseOnFocusLoss: false,
            theme: "colored",
            toastId: `exito-envRev`,
          });
          setReiniciar(true);
        }
      })
      .catch(function (error) {
        console.log(`Error: ${error}`);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.mensaje === "Datos Incorrectos"
        ) {
          console.log("datos incorrectos");
        } else {
          if (
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error.name === "JsonWebTokenError") ||
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error.name === "TokenExpiredError")
          ) {
            console.log("loguearse de nuevo");
            localStorage.clear();
            historial.push("/");
          }
        }
      });
    setEspera(false);
  }

  function verificarImg(img) {
    let validado = false;
    if (img && img.size) {
      validado = true;
    } else {
      validado = false;
      setMensaje("Seleccione una imagen válida");
      setAviso(true);
      setTipoAdvertencia("error");
    }
    return validado;
  }

  function handleSubmission() {
    if (verificarImg(imgEnviar)) {
      subirImagen();
    }
  }

  return (
    <Modal
      titulo={titulo}
      modalAbierto={modalAbierto}
      setModalAbierto={setModalAbierto}
      guardarDatos={handleSubmission}
      mensaje={mensaje}
      tipoAdvertencia={tipoAdvertencia}
      aviso={aviso}
      btnDeshabilitado={btnDeshabilitado}
      setAviso={setAviso}
      tamanio={"l"}
      cargando={espera}
      actualizar={actualizar}
      setActualizar={setActualizar}
      reiniciar={reiniciar}
    >
      <form className={classes.root} noValidate autoComplete="off">
        <Typography id="primaria" style={{ textAlign: "center" }}>
          <b>Imagen Revista</b>
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          style={{ alignItems: "center" }}
        >
          <img
            className={classes.formImg}
            hidden={!imgRevista}
            src={imgRevista ? imgRevista : ""}
            alt="Imagen Revista"
          />
          <Box
            style={{
              marginTop: "1em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <input
              type="file"
              onChange={(e) => onChangeimgRevista(e)}
              hidden
              id={"imgRevista"}
              accept="image/jpg,image/jpeg,image/png"
              disabled={botonesDeshab}
            />
            <label htmlFor={"imgRevista"}>
              <Button
                variant="contained"
                component="span"
                color="primary"
                size="small"
                disabled={botonesDeshab}
              >
                Cargar IMG
              </Button>
            </label>
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
