import React,{useState} from 'react'
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,
        //Collapse,Paper,Button,Card,
        TableContainer,TableHead,TableRow,Tooltip} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'; 
//import CreateIcon from '@material-ui/icons/Create';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RefreshIcon from '@material-ui/icons/Refresh';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Compra from './Compra'
//import NvoUsuario from './NuevoUsuario'

const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-170,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:800,
    }, 
    tamanioLetra:{
      fontSize:12
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#0F91C1"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
       
    {
      id:1,
      titulo:"Nickname",                                                
      alinear:'center'
    },    
  /*   {
      id:8,
      titulo:"Suscripción",                                                
      alinear:'center'
    },   */  
    {
      id:3,
      titulo:"Pago",                                                
      alinear:'center'
    },
    {
      id:2,
      titulo:"Email",                                                
      alinear:'center'
    },   
    {
      id:4,
      titulo:"IVA",                                                
      alinear:'center'
    },  
    {
      id:5,
      titulo:"Subtotal",                                                
      alinear:'center'
    },
    {
      id:6,
      titulo:"Total",                                                
      alinear:'center'
    },
    
    {
      id:7,
      titulo:"Registro",                                                
      alinear:'center'
    },
            
  ];
const TablaUsuarios = ({listUsu,auxActualizar,actualizar,setActualizar,listTipoUsu,btnCargar }) => {
    const classes = useStyles();
    const [datos, setDatos] = React.useState([])  
    const[sinDatos,guardarSinDatos]= React.useState(true)
    const [espera,setEspera]=React.useState(false);
    const [selectedID, setSelectedID] = React.useState(null);    
    const [modalNvaCompAbierto,setModalNvaCompAbierto]= useState(false); 
    
    React.useEffect(()=>
    {
      
      setDatos(listUsu)        
               
    },[listUsu])

  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)   
  }   

  function abrirModalNvaComp()
  { setModalNvaCompAbierto(true);    
  }
   
  function addCero (valor)
  { let auxValor=0
    let valorStr=valor.toString()
    let ini=valorStr.indexOf(".")
    if (ini!== -1) 
    { let cortado=valorStr.substr(ini,valor.length)
      if (cortado.length === 2  ) 
      {   auxValor=(valorStr+"0")
      }
      else
      {   auxValor=valor+0.00  
      }
    }
    else
    {   auxValor=valor+0.00      
    }
    return auxValor;
  }  

  function Renglon(props)
  {
    const { row } = props;      
    return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        //onContextMenu={(event)=>guadarPosCursor(event,row)} 
        classes={{ hover: classes.hover, selected: classes.selected }}        
      >         
     {/*   <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          <Tooltip title={`Editar Usuario`}>
            <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditCliente(row)} component="span">
              {selectedID === row.Id ? 
                <CreateIcon style={{color:'white',width:18+'px'}} />
                :<CreateIcon style={{width:18+'px'}} color="secondary"/>
              }
            </IconButton>                            
          </Tooltip>                                                                                                                                              
        </TableCell>  */}
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:50+'px', paddingRight:2+'px'}} >
          {row.Id}
        </TableCell>     
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:200+'px',fontSize:12+'px'}} >
          {row.CliNom}
        </TableCell>                  
        {/* <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:80+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
          {row.PeriodoNom}              
        </TableCell> */}
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:100+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
          {row.FormaNom}              
        </TableCell>
        <TableCell  className={classes.tableCell} align="right" padding={'none'} style={{ width:120+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
          {row.CliEmail}              
        </TableCell>
        <TableCell  className={classes.tableCell} align="right" padding={'none'} style={{ width:80+'px',fontSize:12+'px',paddingLeft:8+'px'}} >          
          <NumberFormat thousandSeparator={true}  className={classes.tamanioLetra}
            displayType="text" prefix={'$'} value={addCero(row.Iva)} style={{fontSize:.75+'rem'}}
          />            
        </TableCell>
        <TableCell  className={classes.tableCell} align="right" padding={'none'} style={{ width:80+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
          <NumberFormat thousandSeparator={true}  className={classes.tamanioLetra}
            displayType="text" prefix={'$'} value={addCero(row.Subtotal)} style={{fontSize:.75+'rem'}}
          />            
        </TableCell> 
        <TableCell  className={classes.tableCell} align="right" padding={'none'} style={{ width:80+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
        <NumberFormat thousandSeparator={true}  className={classes.tamanioLetra}
            displayType="text" prefix={'$'} value={addCero(row.Total)} style={{fontSize:.75+'rem'}}
          />                      
        </TableCell>         
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:120+'px',fontSize:12+'px',paddingLeft:8+'px'}} >          
          {moment.utc( row.FReg).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( row.FReg).format('DD/MM/YYYY HH:mm')}       
        </TableCell>              
                         
      </TableRow>        
    </React.Fragment>
  );}

  function tablaCompleta(auxlista){
  //console.log("entrando sin preguntar");
  let colorLetra="#F96550"
  return(
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>     
            {/* <TableCell style={{ color:colorLetra}} 
              key={1}    align={'center'} padding={'none'} 
            >
              <Tooltip title={`Actualizar lista de Usuarios`}>
                <IconButton aria-label="agregar nuevo" onClick={() => btnCargar()}  
                    component="span" size="small" 
                >
                  <RefreshIcon color="secondary"  />  
                </IconButton>
              </Tooltip>                   
            </TableCell>  */}                               
            <TableCell style={{ color:colorLetra}} 
              key={1}    align={'center'} padding={'none'} 
            >
              <Tooltip title={`Crear Usuario`}>
                <IconButton aria-label="agregar nuevo" onClick={() => abrirModalNvaComp()} 
                    component="span" size="small" 
                >
                  <AddCircleOutlineIcon color="secondary"  />  
                </IconButton>
              </Tooltip>              
              <span style={{ verticalAlign: 'middle'}}>
                {auxlista.length}              
              </span>              
            </TableCell>                                
            {columnas.map(row => {              
              return(  
                <TableCell style={{ color:colorLetra}} 
                  key={row.id}    align={row.alinear} padding={'none'} 
                >
                  {row.titulo}
                </TableCell>    
              )                
            })}           
          </TableRow>
        </TableHead>
        <TableBody>
            {auxlista.length!=0 ? (auxlista.map((row) => (
                <Renglon key={row.Id} row={row} />
            ))):null}
        </TableBody>
      </Table>
    </TableContainer>
  )}
    const tablaSinDatos=()=>{  
    return(
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>            
            <TableCell  
              key={1}    align={'center'} padding={'none'} 
            >
              <Tooltip title={`Nueva Compra`}>
                <IconButton aria-label="agregar nuevo" onClick={() => abrirModalNvaComp()} 
                  component="span" size="small" 
                >
                  <AddCircleOutlineIcon color="secondary"  />  
                </IconButton>
              </Tooltip>              
            </TableCell>
            {columnas.map(row => {              
              return(  
              <TableCell style={{ color:'white'}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
              )                
            })}                                                
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow 
          className={classes.tableRow}
        > 
          <TableCell  className={classes.tableCell} align="center"> </TableCell>   
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>   
          <TableCell  className={classes.tableCell} align="center"> </TableCell>         
          <TableCell  className={classes.tableCell} align="center"> </TableCell>          
          <TableCell  className={classes.tableCell} align="left">Usuarios</TableCell>             
          <TableCell  className={classes.tableCell} align="center"></TableCell>
          <TableCell  className={classes.tableCell} align="left">Registrado</TableCell>   
          <TableCell  className={classes.tableCell} align="center"></TableCell>                    
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    )}
     
  const contenido = sinDatos&&datos.length!=0 ?tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 
  const modalCompra = <Compra modalAbierto={modalNvaCompAbierto} setModalAbierto ={setModalNvaCompAbierto } />
 /*  const modNvoUsr = modalNvoUsrAbierto ? 
                      ( <NvoUsuario modalAbierto={modalNvoUsrAbierto}  
                          setModalAbierto={setModalNvoUsrAbierto} titulo={'Nuevo Usuario'}
                          setActualizar={setActualizar} actualizar={actualizar}
                          listTipoUsu={listTipoUsu}  btnCargar={btnCargar}
                      />
                      ): null;          */                                                                                                                                          
  return (
  <div style={{minWidth:40+'rem',marginTop:.5+'rem',maxWidth:43+'rem'}}>        
    {tabla}
    {modalCompra}
  </div>
  )
}

export default TablaUsuarios
