import React,{useState,useEffect} from 'react'
import {Box,Table,TableBody,TableCell,LinearProgress,
    TableContainer,TableHead,TableRow, Tooltip, IconButton} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import EditarRevista from './EditarRevista';
import MenuRevista from './MenuRevista';
import moment from 'moment';
import NuevaRevista from './NuevaRevista';
const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      width:600,
      minHeight: 280,
      //minWidth:'100%',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: "#d9222d"
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
    {
      id:2,
      titulo:"Alta",                                                
      alinear:'center'
    }
    , {
      id:3,
      titulo:"Nombre",                                                
      alinear:'center'
    },    
  ];
export default function TablaRevista({lista,setSeleccionado,btnCargar,actualizar,setActualizar}) {
  const alturaTabla = window.innerHeight<937 ? window.innerHeight-200 : window.innerHeight-270
  const classes = useStyles();
  const [datos, guardarDatos] = useState([])  
  const [espera,setEspera]= useState(false);
  const [idSelec, setIdSelec] = useState(-1);
  const [menuSeleccionado,setMenuSeleccionado]= useState(); 
  const [modalEditar,setModalEditar]= useState(false); 
  const [modalNuevaRevista, setModalNuevaRevista] = useState(false);   
  const [selecTabla,setSelecTabla] = useState([])
  useEffect(()=>
  {
    setEspera(true)
    guardarDatos(lista)
    setEspera(false)            

  },[lista])

  console.log()

  const filaSelec=(auxRev)=>{
    setIdSelec(auxRev.Id)
    //console.log(auxRev.Id);
    setSeleccionado(auxRev)
    setSelecTabla(auxRev)
  }
  
  const valorInicial = {
    mouseX: null,
    mouseY: null,
  };
  const cerrarMenu = () => {
      setValorCursor(valorInicial);
  };

  const [valorCursor, setValorCursor] = React.useState(valorInicial);

  function guardarPosCursor(event,registro) {
    event.preventDefault();
    setValorCursor({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    //console.log(registro);
    setMenuSeleccionado(registro)
    setIdSelec(registro.Id)
  };
    
  function abrirModalEdit(){
    setModalEditar(!modalEditar);
  }
  function abrirModalNuevaRevista(){  
    setModalNuevaRevista(!modalNuevaRevista);    
  }
function Renglon(props) {
    const { row } = props;      
    return (
    <React.Fragment>
      <TableRow onClick={ () => { filaSelec(row); } }
        className={classes.tableRow} selected={ idSelec === row.Id}
        classes={{ hover: classes.hover, selected: classes.selected }}  
        //onContextMenu={(event)=>guardarPosCursor(event,row)}       
      >
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:10+'px',fontSize:15+'px'}} >
            <b>{row.Id}</b>
        </TableCell>  
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:80+'px',fontSize:15+'px'}} >
            <b>{row.FReg ? moment.utc( row.FReg).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00" ? "" 
              : moment.utc( row.FReg).format('DD/MM/YYYY') : ""}</b>
        </TableCell>   
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:150+'px',fontSize:12+'px',paddingLeft:.5+'rem'}} >
          {row.Nombre}
        </TableCell>   
                                                                        
      </TableRow>        
    </React.Fragment>
    );
  }
  function tablaCompleta(auxlista){
  //console.log("entrando sin preguntar");
  return(
    <TableContainer className={classes.container} style={{height:alturaTabla}}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>     
          <TableCell align="center" padding={'none'} >
            <Tooltip title={`Actualizar tabla`}>
              <IconButton    size="small" onClick={() => btnCargar()} 
                component="span" aria-label="refresh"
              >                
                <RefreshIcon  color="secondary"  />
              </IconButton>
            </Tooltip>             
          </TableCell>  
                                   
            {columnas.map(row => {              
              return(  
                <TableCell style={{ color:'#F96550',fontWeight:600}} 
                  key={row.id}    align={row.alinear} padding={'none'} 
                >
                  {row.titulo}
                </TableCell>    
              )                
            })}           
          </TableRow>
        </TableHead>
        <TableBody>
          {auxlista.length!==0 ? (auxlista.map((row) => (
            <Renglon key={row.Id} row={row} />
          ))):null}
        </TableBody>
      </Table>
    </TableContainer>
  )}

    const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell align="center" padding={'none'} style={{ color:'#F96550',fontWeight:600}}  >
              0
            </TableCell>     
            <TableCell align="center" padding={'none'} >               
              <Tooltip title="Agregar Revista">
                <IconButton   size="small" onClick={abrirModalNuevaRevista}
                    component="span" style={{marginRight:.6+'rem'}}  aria-label="add circle"
                >
                  <AddCircleOutlineIcon style={{marginBottom:8+'px'}} color="secondary"  />  
                </IconButton>
              </Tooltip>     
            </TableCell>         
            {columnas.map(row => {              
              return(  
              <TableCell style={{ color:'#F96550',fontWeight:500}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
              )                
            })}                                                
          </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow}> 
          <TableCell  className={classes.tableCell} align="center"> </TableCell>         
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>          
          <TableCell  className={classes.tableCell} align="left">revistas</TableCell>             
          <TableCell  className={classes.tableCell} align="center">registradas</TableCell>       
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
    )}
     
  const menuRev = valorCursor.mouseY !== null ? <MenuRevista valorCursor={valorCursor} handleClose={cerrarMenu}
                                                  seleccionado={menuSeleccionado} setActualizar={setActualizar} actualizar={actualizar}/>
                                                  :null
  const modalEd = modalEditar !== null ? <EditarRevista modalAbierto={modalEditar} setModalAbierto={setModalEditar}
                                                  seleccionado={selecTabla} titulo={'Editar '+selecTabla.Nom} setActualizar={setActualizar} actualizar={actualizar} />
                                                  :null
  const modNvaRev = modalNuevaRevista ? (<NuevaRevista modalAbierto={modalNuevaRevista} setModalAbierto={setModalNuevaRevista}
                                            setActualizar={setActualizar} actualizar={actualizar}/>) : null;                                                   
  const contenido = datos.length!==0 ? tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )


  return (
  <div>      
    {tabla}  
    {menuRev}      
    {/* modalEd */}
    {/* modNvaRev */}
  </div>
  )
}
