import React, { useEffect, useState } from "react";
import {Button, Dialog, DialogActions,
        DialogContent, Typography, CircularProgress} from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    alignItems: 'center',
    width: '20rem'
  },
  wrapper: {    
    marginTop: 0,
    paddingTop:0,
    position: 'relative',
  },
  buttonGuardar:{color: green[500],},
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ModalCerrar2({titulo,children,modalAbierto,setModalAbierto,
                                      guardarDatos,mensaje, tipoAdvertencia,reiniciar,setActualizar,actualizar,
                                      aviso, setAviso, tamanio,btnDeshabilitado,cargando}) {
  //const [open, setOpen] = React.useState(false);  
  const [scroll, setScroll] = useState('paper');      

  const cerrarBorrar = () => {    
    //setOpen(false);
    setAviso(false);
    setModalAbierto(false);
    if(reiniciar){
      setActualizar(!actualizar)
    }
  };
  const handleClose = () => {
  //  setOpen(false);
    setModalAbierto(false)
  };

  const handleCloseSN = () => {
    setAviso(false);    
  };  
  const classes = useStyles();    
  const descriptionElementRef = React.useRef(null);
  
  useEffect(() => {
      if (modalAbierto) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      } 
     // console.log("dentro de pub2");
  }, [modalAbierto]);
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  return (
  <div>         
    <Dialog
      open={modalAbierto} //onClose={handleClose}
      scroll={scroll} aria-labelledby="titulo"
      aria-describedby="descripcion" fullWidth={false}
      maxWidth={tamanio}
    >
      <DialogTitle style={{color:'#F96550'}} id="titulo" align="center" >
        {titulo}
      </DialogTitle>
            
      <DialogContent dividers={scroll === 'paper'} >        
        { aviso && tipoAdvertencia && mensaje.length!==0 ? 
            <Alert severity={tipoAdvertencia}  onClose={handleCloseSN}>{mensaje}</Alert>
          :   <span></span>
        }

        {children}        
      </DialogContent>
  
      <DialogActions>
        <Button onClick={cerrarBorrar} disabled={cargando} color="secondary"  variant="outlined">
            Cerrar
        </Button>
        <div className={classes.wrapper}>
          <Button
            variant="outlined"   className={classes.buttonGuardar}
            disabled={btnDeshabilitado ? true: cargando}
            onClick={guardarDatos}
          >
            Guardar
          </Button>
          {cargando && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>                    
      </DialogActions>
    </Dialog>    
  </div>
  );
}