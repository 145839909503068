import React from 'react';
import { Card, CardMedia, Typography} from '@material-ui/core';
const CardImgPortada = ({seleccionado}) => {
   /*  React.useEffect(()=>{
        console.log(seleccionado);
    }) */
    return( 
    <>  <Typography variant="h5">Portada</Typography>
        <Card style={{width:15+'rem'}}>
            {seleccionado.Portada!=="" ?<CardMedia //style={{width:"10rem"}}
                component="img" alt="Portada Revista Magazine"
                height="300" title="Portada Revista Magazine"
                image={seleccionado.Portada} 
            />:
                <CardMedia 
                    style={{height:"12rem",width:"13rem",paddingLeft:1.8+'rem',
                        paddingTop:.5+'rem',paddingBottom:.5+'rem'}}
                    component="img" alt="Portada Revista Magazine"
                    title="Portada Revista Magazine" image={"/T_LOGO_1.svg"} 
                />
            }
        </Card>
        <Typography variant="h5">Miniatura</Typography>
        <Card style={{width:15+'rem'}}>
            {seleccionado.Mini!=="" ?<CardMedia //style={{width:"10rem"}}
                component="img" alt="Portada Revista Magazine"
                height="200" title="Portada Revista Magazine"
                image={seleccionado.Mini} 
            />:
                <CardMedia 
                    style={{height:"12rem",width:"13rem",paddingLeft:1.8+'rem',
                        paddingTop:.5+'rem',paddingBottom:.5+'rem'}}
                    component="img" alt="Portada Revista Magazine"
                    title="Portada Revista Magazine" image={"/T_LOGO_1.svg"} 
                />
            }
        </Card>
    </>
    );
};

export default CardImgPortada;
