import React,{useState, useEffect} from 'react'
import {Box,LinearProgress, 
        //Select,MenuItem,FormControl,InputLabel,IconButton, Tooltip  
        } from '@material-ui/core';
//import { makeStyles } from '@material-ui/core/styles';
//import SearchIcon from '@material-ui/icons/Search';
//import RefreshIcon from '@material-ui/icons/Refresh';
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import TablaRevistaTag from './TablaRevistaTag';
import TablaRevista from './TablaRevista';
import NuevaRevista from './NuevaRevista';
import axios from 'axios';
import qs from 'qs';
import CardImg from './CardImgPortada'
/*const useStyles = makeStyles((theme) => ({
    botonCrear:{
        marginTop:'.2em',
        width:'20',
        height:'20'
    },
    botonBuscar:{
        marginTop:'.2em',
        width:'20',
        height:'20'
    },
    formSelect:{
        width:'12em',
    }
  }));*/


export default function RevistaInicio(){
    //const classes = useStyles();
    const [listCategoria,setListCategoria] = useState([])
    //const [listaRevistas, setListaRevistas] = useState([])
    const [listaFiltro,setListaFiltro] = useState([])
    const [seleccionado,setSeleccionado] = useState([])
    const [espera, setEspera] = useState(true);
    const [modalNuevaRevista, setModalNuevaRevista] = useState(false);
    const [actualizar,setActualizar]=React.useState(false);
    //const [categoria,setCategoria] = useState(0);
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    let historial = useHistory();     
    
    useEffect(()=>
    { 
        const source = axios.CancelToken.source();    
       /*  async function getCategorias(){
            setEspera(false)
            let auxU=process.env.REACT_APP_LINK +`/revista-categoria-list`;  
            let usuid = localStorage.getItem('UsuId') 
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({                               
                'usuario':usuid,
                'idDispositivo':'wed4513s1c2s1cds1cd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: {
                    "access-token": token,
                    "Content-Type": "application/x-www-form-urlencoded",
                    },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux[0].Id>-1){
                    let arrAux= aux
                    arrAux.splice(0,0,{
                        Id: 0,
                        Descr: "Seleccione una Opción",
                        });
                    setListCategoria(arrAux)
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
            })
            setEspera(false)
        } */

        async function getRevistas(){
            setEspera(true)
            let auxU=process.env.REACT_APP_LINK +`/revista-list`;  
            let usuid = localStorage.getItem('UsuId') 
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({                               
                'usuario':usuid,
                'idDispositivo':'wed4513s1c2s1cds1cd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: {
                    "access-token": token,
                    "Content-Type": "application/x-www-form-urlencoded",
                    },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;   
                if(aux[0].Id>-1){
                    //setListaRevistas(aux)
                    let ord = aux.sort(function (a, b) { return a.Id - b.Id; }); 
                    setListaFiltro(ord)
                }else{
                    listaFiltro([])
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
            })
            setEspera(false)
        }

        const autenticado = authUser();
        if(!autenticado)
        {
            localStorage.clear();
            historial.push('/');
            guardarUsuario([]);    
        }else{
            const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');           
            if (usuario.length===0) 
            {
                guardarUsuario({Nombre:usu,nivel:nvl});                                         
                
            }        
        // document.title = 'Revistas'                                 
            guardarGenerales({...generales,Titulo:'Revistas'})   
            //getCategorias();
            getRevistas()
        }
},[actualizar])
    
    function abrirModalNuevaRevista(){  
        setModalNuevaRevista(!modalNuevaRevista);    
    }
    
    const btnCargar =()=>{
        setActualizar(!actualizar)        
    } 
    
  /*function onChangeCategoria(e){
        setCategoria(e.target.value)
    }*/

    /*function buscarElementos(){
        if(categoria!=0){
            var aux = listaRevistas.filter(function (val) {
                return val.catId === categoria;
              });
              setListaFiltro(aux)
        }else{
            setListaFiltro(listaRevistas)
        }
    }
    */
    const modNvaRev = modalNuevaRevista ? (<NuevaRevista modalAbierto={modalNuevaRevista} setModalAbierto={setModalNuevaRevista} />) : null;
    const mostrarLista = listaFiltro.length!==0 ? <TablaRevista lista={listaFiltro} seleccionado={seleccionado} setActualizar={setActualizar} actualizar={actualizar}
                                                    setSeleccionado={setSeleccionado} btnCargar={btnCargar} />  : null;

    return(
    <div style={{marginLeft:'1em', marginTop:'1rem'}}>
        {espera?  <Box pt={3} style={{width:'600px'}}><LinearProgress/> </Box> :
        <div>
            <div style={{display:'flex', flexDirection:'row'}}>            
        </div>
        <Box display="flex" flexDirection="row">
            <Box style={{marginRight:'1em'}}>
                {mostrarLista}
            </Box>
            <Box style={{width:'20%'}}>
                {seleccionado.length!==0 ?
                <span>
                    <CardImg seleccionado={seleccionado}/>
                    <TablaRevistaTag listaCatego={listCategoria} seleccionado={seleccionado}/>                
                </span>
                :null}                
            </Box>            
        </Box>
        {modNvaRev}
        </div>}
    </div>
    )
}