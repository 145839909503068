import { Box, LinearProgress, Typography,IconButton ,Tooltip} from '@material-ui/core'
import React, {useState,useEffect} from 'react'
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
//import PaginasInicio from '../publicacionPaginas/PaginasInicio';
import ArchivoInicio from '../publicacionarchivos/ArchivoInicio';
import TablaPublicacion from './TablaPublicacion'
import NuevaPublicacion from './NuevaPublicacion';
import axios from 'axios'
import qs from 'qs'

export default function PublicacionInicio(){
    const [revista,setRevista] = useState({}); 
    const [seleccionado, setSeleccionado] = useState([])
    const [espera, setEspera] = useState(false);
    const [listaPublicacion,setListaPublicacion] = useState([])
    const [actualizar,setActualizar]=React.useState(false);
    const [modalNuevaPublicacion, setModalNuevaPublicacion] = useState(false);
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
    let historial = useHistory();     
    useEffect(()=>
    { 
        const source = axios.CancelToken.source();  
        const auxRev = qs.parse(localStorage.getItem('revista')) 
        async function getPublicaciones(){
            setEspera(true)
            let auxU=process.env.REACT_APP_LINK +`/revista-publicacion-list`;  
            let usuid = localStorage.getItem('UsuId') 
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({     
                'revista':auxRev.Id,                          
                'usuario':usuid,
                'idDispositivo':'wed4513s1c2s1cds1cd'
                });
                let config = {
                    url:auxU ,
                    method: 'POST',
                    headers: {
                        "access-token": token,
                        "Content-Type": "application/x-www-form-urlencoded",
                      },
                    data: dataInfo,
                    cancelToken: source.token
                };
                let aux ={};           
                await axios(config)
                .then(function (response) {          
                    aux=  response.data;    
                    if(aux[0].Id>-1){
                        //let ord = aux.sort(function (a, b) { return a.Id - b.Id; }); 
                        setListaPublicacion(aux)
                    }
                }).catch(function (error) {          
                    console.log(`Error: ${error}`);        
                    if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                    {
                      console.log('datos incorrectos' );  
                    }
                })
                setEspera(false)
        }
      const autenticado = authUser();
      if(!autenticado){
        localStorage.clear();
        historial.push('/');
        guardarUsuario([]);    
      }else{
        const usu= localStorage.getItem('UsuarioNom');
        const nvl= localStorage.getItem('Tipo');           
        if (usuario.length===0) 
        {
            guardarUsuario({Nombre:usu,nivel:nvl});                                         
            
        }        
        //document.title = 'Publicaciones'      
        guardarGenerales({...generales,Titulo:'Publicaciones'})   
        setRevista(auxRev)
        getPublicaciones()

    }},[actualizar])
    
    function abrirModalNuevaPublicacion(){  
        setModalNuevaPublicacion(!modalNuevaPublicacion);    
    }

   const datosRevista=
    (<div>
        <Box display="flex" row width="100%"   flexWrap="wrap">            
            <Tooltip title={`Regresar a Revistas`}>
                <IconButton    style={{marginLeft:.5+'rem', marginBottom:7+'px'}} size="small" onClick={() =>  historial.push('/revista')} 
                     component="span" aria-label="refresh"
                >                
                    <ArrowBackIosIcon  color="secondary"  />
                </IconButton>
            </Tooltip>           
            <Tooltip title={`Actualizar tabla`}>
                <IconButton    size="small" onClick={() => setActualizar(!actualizar)} 
                    style={{marginRight:.6+'rem', marginBottom:7+'px'}} component="span" aria-label="refresh"
                >                
                    <RefreshIcon  color="secondary" />
                </IconButton>
            </Tooltip>           
            <Tooltip title={`Nueva publicación`}>
                <IconButton   size="small" onClick={abrirModalNuevaPublicacion}
                    component="span" style={{marginRight:.6+'rem',marginBottom:8+'px'}}  aria-label="add circle"
                >
                    <AddCircleOutlineIcon color="secondary"  />  
                </IconButton>
            </Tooltip>
            {revista.fAlta ?
                ( ( moment.utc( revista.fAlta).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00") ? null 
                    :   
                    <Box display="flex" pr={1}>
                        <Typography variant="h6" component="h2" color="primary">
                            {'Alta'}
                        </Typography>
                    </Box>
                )   
            : null}
            {revista.fAlta ? 
                ( ( moment.utc( revista.fAlta).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00") ? null 
                    :
                    <Box display="flex" pr={3}>
                        <Typography variant="h6" component="h2" >
                            {moment.utc(revista.fAlta).format("DD/MM/YYYY") }
                        </Typography>
                    </Box>
                ) 
            :null}
            <Box display="flex" pb={1}>
                <Typography variant="h6" component="h2"  >
                    {revista.Nom}
                </Typography>
            </Box>                                
        </Box>     
    </div>)

    const modNvaPubl = modalNuevaPublicacion ? (<NuevaPublicacion modalAbierto={modalNuevaPublicacion} 
    setModalAbierto={setModalNuevaPublicacion} rev={revista} actualizar={actualizar} setActualizar={setActualizar}/>) : null;
    const auxDatos =revista.length!==0 ? datosRevista:null

    return (
        <div style={{marginLeft:'1em'}}>
            {!espera ? 
            <div>
            {auxDatos}
            {revista.length!==0 ? <Box display="flex" row width="100%"  flexWrap="wrap">
                <Box display="flex" width={500} >
                    <TablaPublicacion setSeleccionado={setSeleccionado} lista={listaPublicacion}
                     rev={revista} seleccionado={seleccionado} actualizar={actualizar} setActualizar={setActualizar}/>
                </Box>
                {
                <Box display="flex" width="35%">
                    {seleccionado.length!==0 ?
                    /*<PaginasInicio publicacion={seleccionado} revista={revista}/>*/
                    <ArchivoInicio publicacion={seleccionado} revista={revista}/> :null}
                </Box> 
                }               
            </Box>
            :null}
            {modNvaPubl}
            </div>
            :<Box sx={{width:'100%'}} pt={3}>
                <LinearProgress/></Box>}
        </div>
    )
}