import React from 'react'
import {Box,TextField, LinearProgress} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import Modal from '../generales/ModalCerrar';
import Autocomplete from "@material-ui/lab/Autocomplete";
const Compra = ({modalAbierto,setModalAbierto }) => {
    let history = useHistory();
    const [espera,setEspera]=React.useState(false);
    const [mensaje, setMensaje] = React.useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = React.useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= React.useState(false)
    const [listPago, setListPago] = React.useState([]); 
    const [listSuscripcion, setListSuscripcion] = React.useState([]); 
    const [listCliente, setListCliente] = React.useState([]); 
    const [aviso, setAviso] = React.useState(false);
    const source = axios.CancelToken.source(); 
    const[compra,setCompra]= React.useState({cliente:'',forma:'',pedido:'',pago:'',suscripcion:''});
    const{cliente,forma,pedido,pago,suscripcion}=compra
    React.useEffect(()=>{
        //const source = axios.CancelToken.source(); 
        let idUsu =localStorage.getItem('UsuId') ;     
        let token =localStorage.getItem('token20') ;     
        async function llenaListPago()  {                       
            let auxU=process.env.REACT_APP_LINK +`/compra-forma-pago`;                                           
            let dataInfo = qs.stringify({     
                'usuario':idUsu  ,            
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;                                 
                if (aux.length !== 0) 
                {   console.log(aux); 
                    setListPago(aux)                    
                }                                         
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                } 
            }
            });                            
        };
        async function llenaListSuscripcion()  {                       
            let auxU=process.env.REACT_APP_LINK +`/compra-suscripcion`;                                           
            let dataInfo = qs.stringify({     
                'usuario':idUsu  ,            
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;                                 
                if (aux.length !== 0) 
                {   console.log(aux); 
                    setListSuscripcion(aux)                    
                }                                         
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                } 
            }
            });                            
        };
        async function llenaListClientes()  {                       
            let auxU=process.env.REACT_APP_LINK +`/compra-cliente-list`;                                           
            let dataInfo = qs.stringify({     
                'usuario':idUsu  ,            
                'idDispositivo':'8978yuhjnfrsdsd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {         
                aux=  response.data;                                 
                if (aux.length !== 0) 
                {   console.log(aux); 
                    setListCliente(aux)                    
                }                                         
            })
            .catch(function (error) {          
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                    console.log('loguearse de nuevo');
                    localStorage.clear();                      
                    history.push("/");                              
                } 
            }
            });                            
        };
        llenaListPago()
        llenaListClientes()
        llenaListSuscripcion()
    },[])

    const guardarPago=(e)=>{
        //console.log(e);
        if (e!== null && e!== undefined) 
        {
            setCompra({...compra,pago:e})    
            setAviso(false)         
        }
        else
        {
            setCompra({...compra,pago:''})    
            //console.log("Sin valores")
        }
        //setAuxBandera(0)
    }

    const guardarCliente=(e)=>{
        //console.log(e);
        if (e!== null && e!== undefined) 
        {   setCompra({...compra,cliente:e})
            setAviso(false)                     
        }
        else
        {   setCompra({...compra,cliente:''})             
        }        
    }

    const guardarSuscripcion=(e)=>{
        //console.log(e);
        if (e!== null && e!== undefined) 
        {   setCompra({...compra,suscripcion:e}) 
            setAviso(false)                   
        }
        else
        {   setCompra({...compra,suscripcion:''})                
        }    
    }

    const onChange = e =>
	{   //let expNopermitida = new RegExp('[#._:!%-({})/*=?|]');
        let expNopermitida = new RegExp('[.%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');     
        //let expCadena = new RegExp('[A-Za-z0-9$]');
        let expCadena = new RegExp('[A-Za-z0-9$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setCompra({
                ...compra, //copiamos el compra
                [e.target.name] : e.target.value.toUpperCase()  
            })
            setAviso(false)                     
        }		
	}; 

    const contenidoModal =(
        <Box>          
            {listCliente.length !== 0 ?
                <Autocomplete
                    id="pago-demo" size='small' options={listCliente}
                    onChange={(e,option)=>guardarCliente(option)}                
                    getOptionLabel={(option) => option.Nickname}
                    style={{ width: 15+'rem',marginTop:0.8+'rem' }}
                    noOptionsText={"Sin coincidencias"}
                    renderInput={(params) => (
                        <TextField {...params} color="secondary"
                        label="Cliente" variant="outlined" />
                    )}
                />
                : null
            }  
            <br/>              
            {listSuscripcion.length !== 0 ?
                <Autocomplete
                    id="pago-demo" size='small' options={listSuscripcion}
                    onChange={(e,option)=>guardarSuscripcion(option)}                
                    getOptionLabel={(option) => option.Alias}
                    style={{ width: 15+'rem' }}
                    noOptionsText={"Sin coincidencias"}
                    renderInput={(params) => (
                        <TextField {...params} color="secondary"
                        label="Suscripción" variant="outlined" />
                    )}
                />
                : null
            }
            <br/>
            {listPago.length !== 0 ?
                <Autocomplete
                    id="pago-demo" size='small' options={listPago}
                    onChange={(e,option)=>guardarPago(option)}                
                    getOptionLabel={(option) => option.Descr}
                    style={{ width: 15+'rem' }} color="secondary"
                    noOptionsText={"Sin coincidencias"}
                    renderInput={(params) => (
                        <TextField {...params} color="secondary"
                        label="Método de Pago" variant="outlined" />
                    )}
                />
                : null
            } 
            <br/>
            <TextField   id="pedido-input" onChange={onChange} variant="outlined"     
                style={{paddingBottom:.5+'rem',width:9+'rem',marginRight:.5+'rem'}}
                label="Pedido" value={pedido} name='pedido'size="small"  
                color="secondary"              
            />
        </Box>        
    )
    async function llamadaNvaCompra( )  {       
        setEspera(true);
        let auxU=process.env.REACT_APP_LINK +`/compra-cliente-simulada`;                               
        let token =localStorage.getItem('token20') ;               
       // let usuId =localStorage.getItem('UsuId') ;                                      
        let dataInfo = qs.stringify({                                                                               
            'cliente':cliente.Id,
            'suscripcion':suscripcion.Id,
            'forma':pago.Id,
            'refer':'E4CDS2',
            'token':'RF48E5HJM54K2FDVF',
            'pedido':pedido ,//'SUSC-23',
            'idDispositivo':'451224452121'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {              
          aux=  response.data;                                          
            console.log(aux)
          if (aux.length!==0) 
          {                
            //setNvoRegistro(aux[0]);  
           //btnCargar()            
            setBtnDeshabilitado(true)                                             
            let mensaje="Se registro correctamente la Nueva Compra"
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true)     
          }                                            
        })
        .catch(function (error) {                  
            console.log(`Error: ${error}`);              
            if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
            {
                console.log('datos incorrectos' );  
            }else
            {
                if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                    ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                {
                console.log('loguearse de nuevo');
                localStorage.clear();                      
                history.push("/");                              
                } 
            }
        });             
        setEspera(false);         
    };
    function validaSelectNulos(valor,cadena){
        let respuesta=false
        if (valor!==null &&  valor!==null  && valor.length!==0) 
        {
            respuesta=true
        } 
        else 
        {
            let mensaje="Debe seleccionar"+cadena
            setTipoAdvertencia("warning")      
            setMensaje(mensaje);
            setAviso(true)     
        }
        return( respuesta)
    }
    
    function validaNulos(valor,cadena){
        let respuesta=false
        if (valor!==null &&  valor!==null  && valor.length!==0   ) 
        {
            respuesta=true
        } 
        else 
        {
            let mensaje="Debe llenar el campo"+cadena
            setTipoAdvertencia("warning")      
            setMensaje(mensaje);
            setAviso(true)     
        }
        return( respuesta)
    }

    function guardarDatos(e)
    {   e.preventDefault()
        if (validaSelectNulos(cliente,' un Cliente') && validaSelectNulos(suscripcion,' una Suscripción') 
            && validaSelectNulos( pago,' un Método de pago') && validaNulos( pedido,' Pedido')) 
        {
            console.log("guardando  modal");    
            llamadaNvaCompra()                                              
        }
        
    }   

    return (
    <Modal
        titulo={'Nueva Compra'} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
        aviso={aviso} btnDeshabilitado={btnDeshabilitado}
        setAviso={setAviso} tamanio={'sm'} cargando={espera}        
    >
        {contenidoModal}
    </Modal> 
    )
}

export default Compra