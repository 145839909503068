import React, {  useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, LinearProgress, Typography, Button, IconButton,Tooltip} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useHistory } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'
import { authUser } from '../funciones/AuthUser'
import ArchivoVisualizador from "./ArchivoVisualizador";
import Progress from "./Progress";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PDFDocument } from 'pdf-lib'
import axios from 'axios';
import qs from 'qs'
const useStyles = makeStyles((theme) => ({
    root: {
      //alignContent: 'center',
      marginTop : '.5em',
      paddingLeft:1.5+'rem',
      width:'650'
    },
    botones: {
        marginTop: '.5em',
        marginBottom: '.5em',
        marginRight: '.5em',
        height:'3em'
    },
    botonSubir:{
        marginTop:'1.5em',
        height:'3em'
    },
    alturaLista:{
        minHeight: 280,
        maxHeight: window.innerHeight-220,
        overflow: 'auto',
        alignContent:'center',
        //marginTop:'1rem'
    },
}));

export default function ArchivoInicio({publicacion,revista}){
    const classes = useStyles();
    const [espera,setEspera] = useState(false)
    const [listaPag, setListaPag] = useState([])
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const [archivoSubido,setArchivoSubido] = useState([])
    const [archivoPreview,setArchivoPreview] = useState([])
    const [deshabilitado,setDeshabilitado] = useState(false)
    const [progresoEnvio, setProgresoEnvio] = useState(0);
    const [actualizar,setActualizar] = useState(0);
    const [nombreSubido,setNombreSubido] = useState("")
    const [numPag,setNumPag] = useState(0)
    let historial = useHistory();  
    useEffect(()=>{
        const source = axios.CancelToken.source();    
        async function getPaginas(){
            setEspera(true)
            //let auxU=process.env.REACT_APP_LINK +`/publicacion-contenido-list`;  
            let auxU=process.env.REACT_APP_LINK +`/publicacion-contenido-pdf-web`;  
            let usuid = localStorage.getItem('UsuId') 
            let token = localStorage.getItem("token20");
            let dataInfo = qs.stringify({  
                'publicacion': publicacion.Id,                             
                'usuario':usuid,
                'idDispositivo':'wed4513s1c2s1cds1cd'
                });
            let config = {
                url:auxU ,
                method: 'POST',
                headers: {
                    "access-token": token,
                    "Content-Type": "application/x-www-form-urlencoded",
                    },
                data: dataInfo,
                cancelToken: source.token
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;
                //console.log(aux);
                if(aux.Id&&parseInt(aux.Id)>-1){
                    //let archPdf = aux.find(elem=>elem.Ext==="pdf")
                    if(aux.Ext==="pdf"){
                        setListaPag(aux)
                  //      console.log(aux);
                    }else{
                        setListaPag([])
                    }
                }else{
                    setListaPag([])
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }
            })
            setEspera(false)
        }

        const autenticado = authUser();
        if(!autenticado)
        {   localStorage.clear();
            historial.push('/');
            guardarUsuario([]);    
        }
        else
        {   const usu= localStorage.getItem('UsuarioNom');
            const nvl= localStorage.getItem('Tipo');           
            if (usuario.length===0) 
            {   guardarUsuario({Nombre:usu,nivel:nvl});                                                         
            }
            getPaginas()
            setArchivoPreview([])
            setArchivoSubido([])
            setProgresoEnvio(0)
            setDeshabilitado(false)
        }
    },[publicacion,actualizar])


    function validarNulos(cadena){
        let respuesta=false
        if (cadena !== null && cadena !== undefined && cadena !== "" 
            && cadena !== ''&& cadena !== ' ') 
        {
            respuesta =true  
        }
        return respuesta
    }

    function subirImagenes(){
        if(archivoSubido.length!==0 && numPag>0){
        const source = axios.CancelToken.source();    
        async function subirPaginas(){
            setDeshabilitado(true)
            let auxU=process.env.REACT_APP_LINK +`/publicacion-envio-pdf`;  
            let token = localStorage.getItem("token20");
            var bodyFormData = new FormData();
            let sizeMB=parseFloat((archivoSubido.size/1000000).toFixed(2))
            bodyFormData.append('publicacion',publicacion.Id)
            bodyFormData.append('total',numPag)
            bodyFormData.append('idDispositivo','wed4513s1c2s1cds1cd')
            bodyFormData.append('multi-files',archivoSubido)
            let config = {
                url:auxU ,
                method: 'POST',
                headers: {
                    "access-token": token,
                    'size':sizeMB,
                    'tipo':'REVISTA-PDF',
                    'revista':publicacion.Id,
                    "Content-Type": 'multipart/form-data',
                    },
                data: bodyFormData,
                cancelToken: source.token,
                onUploadProgress: data => {
                    let progreso= ((100 * data.loaded) / data.total).toFixed(2)
                    setProgresoEnvio(progreso)
                },
            };
            let aux ={};           
            await axios(config)
            .then(function (response) {          
                aux=  response.data;    
                if(aux.success){
                    setProgresoEnvio(0)
                    toast.success(`${nombreSubido} enviado exitosamente`,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `exito-env`
                    })
                    setActualizar(actualizar+1)
                    setArchivoPreview([])
                    setArchivoSubido([])
                    setNombreSubido("")
                }
            }).catch(function (error) {          
                console.log(`Error: ${error}`);        
                if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
                {
                    console.log('datos incorrectos' );  
                }else
                {
                    if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                        ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
                    {
                        console.log('loguearse de nuevo');
                        localStorage.clear();                      
                        historial.push("/");                              
                    } 
                }
            })
                setDeshabilitado(false)
        }
        subirPaginas()
    }
    }



    function onChangeArchivos(event){
       setArchivoPreview([])
       setArchivoSubido([])
        const regex = /([a-zA-Z0-9\s_\\.\-\(\):])+(.pdf)$/
        let regex2 = new RegExp("[$%@#&+(){}/:;!?,<>= ]", "g");      
        if(event.target.files[0]){
                let arch = event.target.files[0]
                let nom ="";
                    if(arch.name.length>=25){
                        nom =  arch.name.substring(0,24)+"..."+arch.name.substring(arch.name.lastIndexOf("."),arch.name.length);                        
                    }else{
                        nom = arch.name;
                    }
                    //let auxNombre=arch.name.replace(/ /g, "") //'probandoNuevo.pdf' 
                    let auxNombre = arch.name.replace(regex2, ""); //'probandoNuevo.pdf'  
                    let myNewFile = new File([arch], auxNombre, {type: arch.type});
                    
                    setNombreSubido(nom)
                 toast.info(`Archivo seleccionado ${nom}`,{
                    pauseOnFocusLoss: false,
                    theme: "colored",
                    toastId: `arc-sel`
                  });
                if(arch && arch.name.toLowerCase().match(regex)){
                    console.log(arch);
                    console.log(myNewFile);
                   setArchivoSubido(myNewFile)
                   setArchivoPreview(URL.createObjectURL(arch))// URL.createObjectURL() crea un url temporal para usar en un img
                   /*pdfjs.disableWorker=true
                   pdfjs.getDocument(URL.createObjectURL(arch)).promise.then(function (doc) {
                    var numPages = doc.numPages;
                    console.log(doc)
                    console.log('# Document Loaded');
                    console.log('Number of Pages: ' + numPages);
                    })
*/
                   /* var reader = new FileReader();
                    reader.readAsBinaryString(arch);
                    pdf(URL.createObjectURL(arch)).then(function(data){
                        console.log(data.numpages)
                    })*/
                   /* var reader = new FileReader();
                    reader.readAsBinaryString(arch);
                    reader.onloadend = function(){
                        var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
                        //console.log('Páginas:',count );
                        setNumPag(count)
                    }*/

                    console.log(getNumPag(URL.createObjectURL(arch)))
                   /* getPageCount(URL.createObjectURL(arch))
                        .then(pages => {
                            console.log(pages);
                        })
                        .catch((err) => {
                            console.log(err);
                        });*/
                }else{
                    toast.warning(`Formato de archivo incorrecto en ${nom} ` +"seleccione un archivo pdf.",{
                        pauseOnFocusLoss: false,
                        theme: "colored",
                        toastId: `incorrecto-sel`
                      }); 
                      setArchivoSubido([])
                      setArchivoPreview([])
                }
        }else{
            toast.info("Seleccione archivo tipo pdf",{
                pauseOnFocusLoss: false,
                theme: "colored",
                toastId: `no-sel`
              });
        }
            
    }

    async function getNumPag(url) {
        const docArray = await fetch(url).then(res => res.arrayBuffer())
        const docCargado = await PDFDocument.load(docArray)
        const numPaginas= docCargado.getPageCount()
        setNumPag(numPaginas)
    }

    function generarContenedor(){
        var elem=<div></div>
        if(listaPag.length!==0 && archivoPreview.length===0){
           elem= <ArchivoVisualizador archivo={listaPag} publiNombre={publicacion.Nombre} />
        }else{
            if(archivoPreview.length!==0){
                elem = <ArchivoVisualizador archivo={archivoPreview} publiNombre={publicacion.Nombre} />
            }else{
                elem =  <Typography variant="h6" component="h2"  >
                            No se encontró ningún archivo
                        </Typography>
            }
        }
        return elem
    }

    const auxNombre=
        <Typography component="h5">
            <b>{`${validarNulos(publicacion.Nombre)?publicacion.Nombre:revista.Nom}`}</b>
        </Typography>
    return( 
    <div className={classes.root}>
        {/*subirArchivos*/}
        <Box display="flex" row flexWrap="wrap" style={{marginBottom:'1em'}}>
            <Box display="flex" pr={1}>
                <Typography component="h5" color="secondary">
                    Visualización de
                </Typography>
            </Box>
            <Box display="flex">
                {auxNombre}
            </Box>
            {archivoSubido.length!==0 &&!espera? 
                <Button onClick={subirImagenes} variant="contained" size="small" color="primary"
                    style={{marginLeft:'1em'}} disabled={deshabilitado}>
                    Enviar
                </Button>
            :null}
            {!espera && progresoEnvio===0 ? 
            <Box>
                <input type="file" onChange={(e)=>onChangeArchivos(e)} name="file" accept="application/pdf" 
                    disabled={deshabilitado} hidden id="cargarArchivos" // este es el objeto que obtiene el archivo pero para sustituirlo visualmente lo hacemos hidden
                /> 
                <label htmlFor={`cargarArchivos`}
                    // con este label   ligamos al input el evento y visualmente mostramos el boton en vez de usar el input por defecto
                >
                    <Tooltip title={`Seleccionar archivo pdf`}>
                        <IconButton size="small" style={{marginLeft:'.5em'}} variant="outlined" component="span" //el componente que lleve el evento debe ser tipo span  
                            disabled={deshabilitado}>
                            <AddCircleOutlineIcon color={!deshabilitado ? "secondary" : "primary"}/>
                        </IconButton>
                    </Tooltip>
                </label>
            </Box>
            :null}            
        </Box>
        <div style={{marginTop:'.2em'}}>
                {archivoSubido.length!==0 &&!espera &&!deshabilitado? 
                    <Typography style={{color:'white',fontSize:'17px'}}>
                        Se visualiza {nombreSubido} con {numPag} páginas
                    </Typography>
                :null}
                {archivoSubido.length!==0  &&progresoEnvio>0? 
                    <Typography style={{color:'white',fontSize:'17px'}}>
                        Enviando {nombreSubido}
                    </Typography>
                :null}
            </div>
        <Box style={{width:'100%', display:'flex', flexDirection:'row', marginTop:'.5em'}}>
            {progresoEnvio===100 ?
                <Typography variant="inherit" className={classes.fuenteTamanioArchivo} 
                    style={{paddingLeft:'.5em',fontSize:'15px', paddingRight:'.5em'}}
                >
                    Espere Por favor...
                </Typography>
            : null}
            {progresoEnvio>0 ? (
                <Progress progress={progresoEnvio}/>)
            :null}
        </Box>
        {espera ? <Box style={{width:'400px'}}>
                    <LinearProgress/>
                </Box>
        : generarContenedor()}
    </div>
    )
}